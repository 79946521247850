export default {
  titulos: {
    listagem: 'Instrumentos',
    novo: 'Novo Instrumento',
    editar: 'Editar Instrumento',
    instrumento: 'Instrumento',
    certificados: 'Certificados',
    historico: 'Histórico por Instrumento',
  },
  certificado: 'Certificado',
  cadastro_sucesso: ' Instrumento salvo com sucesso!',
  edicao_sucesso: ' Instrumento salvo com sucesso!',
  exclusao_sucesso: ' Instrumento excluído com sucesso!',
  duplicar_sucesso: 'Instrumento duplicado com sucesso!',
  responsavel_tecnico_sucesso: 'Responsável técnico alterado com sucesso!',
  tabela_certificado: {
    codigo: 'Código',
    tipo: 'Tipo',
    data: 'Data',
    ordem_servico: 'Ordem de Serviço',
    laboratorio: 'Laboratório',
    tipo_calibracao: 'Tipo de Calibração',
    situacao: 'Situação',
    situacao_avaliacao: 'Situação Avaliação',
  },
  tabela: {
    nome: 'Nome',
    marca: 'Marca',
    tipo: 'Tipo',
    codigo: 'Código',
    modelo: 'Modelo',
    padrao: 'Padrão',
    ativo: 'Ativo',
    faixas: 'Faixas do Modelo de Instrumento',
    descricao: 'Descrição',
    tipo_de_instrumento: 'Tipo de Instrumento',
    pontos_de_calibracao: 'Pontos de Calibração',
    participanteCliente: 'Cliente',
    revisado_conta: 'Revisado pela Conta',
    numero_serie: 'Número de Série',
    ordem_servico: 'Ordem de Serviço',
    horas_execucao: 'Horas de Execução',
    data_execucao: 'Data de Execução',
    executante: 'Executante',
    quantidade: 'Quantidade',
    faixasTable: {
      descricao: 'Descrição',
      faixa: 'Faixa',
      resolucao: 'Resolução',
      valor: 'Valor',
      qtd_pontos_calibracao: 'Quantidade de Pontos',
    },
    pontos: {
      valor: 'Valor',
      descricao: 'Descrição',
      tipoPonto: 'Tipo Ponto',
      origem_ponto: 'Origem do Ponto',
    },
  },
  formulario: {
    nome: 'Nome',
    tipo_de_instrumento: 'Tipo de Instrumento',
    instrumento_padrao: 'Instrumento Padrão',
    utilizado_campo: 'Utilizado em Campo',
    tipo_material: 'Tipo Material',
    copiar_campos_adicionais: 'Copiar os Campos Adicionais',
    cliente: 'Cliente',
    codigo: 'Código',
    codigo_novo_instrumento: 'Código do Novo Instrumento',
    ativo: 'Ativo',
    revisado_unidade_negocio: 'Revisado pela Unidade de Negócio',
    setor: 'Setor do Cliente',
    modelo: 'Modelo',
    numero_serie: 'Número de Série',
    proxima_calibracao: 'Próxima Calibração',
    frequencia_calibracao_meses: 'Frequência Calibração Meses',
    fornecedor_calibracao: 'Fornecedor da Calibracao',
    proxima_verificacao: 'Próxima Verificação',
    frequencia_verificacao_meses: 'Frequência Verificação Meses',
    data_ultima_calibracao: 'Data Última Calibração',
    data_ultima_verificacao: 'Data Última Verificação',
    data_aquisicao: 'Data de Aquisição',
    observacoes: 'Observações',
    marca: 'Marca',
    valor: 'Valor',
    descricao: 'Descricao',
    utiliza_ocr: 'Utiliza Leitor OCR',
    tabela_tipo_instrumento: {
      nome: 'Nome',
      area_de_acreditacao: 'Área de Acreditação',
      setor_responsavel: 'Setor Responsável',
    },
    abas: {
      dados_do_instrumento: 'Dados do  Instrumento',
      faixas: 'Faixas',
      campos_adicionais: 'Campos Adicionais',
      detalhes_registro: 'Detalhes do Registro',
    },
    associar_desassociar_tipos_instrumentos: 'Tipo de Instrumento',
    associar_desassociar_modelos_instrumentos: 'Modelo de Instrumento',
    faixa: {
      nova_faixa: 'Nova Faixa',
      amplitude_minima: 'Amplitude Mínima',
      amplitude_maxima: 'Amplitude Máxima',
      resolucao: 'Resolução',
      unidade_de_medida: 'Unidade de Medida',
      valor: 'Valor',
    },
  },
  validacoes: {
    formulario_invalido: 'Por favor, revise os campos',
    escolher_tipo_instrumento: 'É preciso inserir um Tipo de Instrumento',
    escolher_modelo_instrumento: 'É preciso inserir um Modelo de Instrumento',
    colaborador_atual_diferente_destino:
      'O Colaborador atual deve ser diferente do de destino',
    valor_fora_faixa: 'Valor fora da faixa',
    data_ultima_calibracao:
      'A data selecionada não pode ser anterior à data de aquisição.',
    valor_nao_negativo: 'O valor não pode ser 0 ou negativo',
    pontos_somente_na_edicao: 'Não é possível inserir pontos na criação do instrumento.',
    pontos_faixas_alteradas: 'Houve alterações nas faixas, por favor, salve o instrumento antes de adicionar pontos.',
  },
  erros: {
    id_invalido: 'O id do  Instrumento é inválido',
    selecione_faixa: 'Por favor selecione uma Faixa',
    verificar_campos_adicionais: 'Verifique os Campos Adicionais',
    escolher_tipo_instrumento: 'Escolha um Tipo Instrumento',
    escolher_modelo_instrumento: 'Escolha um Modelo Instrumento',
    codigo_duplicado:
      'Código do novo instrumento não pode ser igual ao código do instrumento selecionado',
    codigoTagJaCadastradoParaEsseParticipante:
      'Código já cadastrado para esse participante',
  },
  botoes: {
    historico_servicos_pecas: "Histórico Serviços/Peças",
    grafico_tendencia: 'Gráfico de Tendência',
    adicionar_faixa: 'Selecionar Faixas',
    selecionar_faixa: 'Selecionar Faixas',
    selecionar_setores: 'Selecionar Setores',
    novo_ponto: 'Novo Ponto de Calibração',
    usar_ponto_modelo: 'Usar Pontos do Modelo',
    selecionar_ponto: 'Selecionar Pontos de Calibração',
    emprestimo_outro_setor: 'Empréstimo Para Outro Setor',
    movimentacao_outro_setor: 'Movimentação para Outro Setor',
    alterar_responsavel: 'Alterar responsável',
    alterar_tecnico_responsavel: 'Alterar Técnico Responsável',
    outras_opecoes: 'Outras Opções',
    registro_ocorrencia: 'Registros de Ocorrências',
    registrar_manutencao_troca_peca: 'Registrar Manutenção / Troca de Peças',
    registros_ocorrencias:
      'Registros de Ocorrências - Instrumento: {codigoInstrumento}',
    duplicar_instrumento: 'Duplicar Instrumento',
    selecionar_pontos: 'Selecionar Pontos de Calibração',
    ativar: 'Ativar',
    inativar: 'Inativar',
    revisar: 'Revisar',
    verificacoes_intermediarias: 'Verificações Intermediárias',
    certificados: 'Certificados',
    historico: 'Histórico',
  },
  filtro: {
    cliente: 'Cliente',
    codigo: 'Código',
    revisado_conta: 'Revisado pela Conta',
    tipo: 'Tipo Instrumento',
    numero_serie: 'Número de Série',
    setor: 'Setor',
    flag_padrao: 'Padrão',
    flag_ativo: 'Ativo'
  },
  campos_adicionais: {
    nome: 'Nome do Campo Adicional',
    valor_padrao: 'Valor no Modelo',
    valor_input: 'Valor no Instrumento',
    input_tipo_campo: 'Digite um valor caso existir',
    input_tipo_campo_obrigatorio: 'Digite um valor',
    alterar_somente_modelo: 'Esse valor só pode ser alterado no modelo',
    aviso_campos_adicionais: 'Para listar os campos adicionais, selecione o <b>Modelo</b> em <b>Dados do Instrumento</b>'
  },
  modal_movimentacao: {
    titulo_movimentacao: 'Movimentação para Outro Setor',
    titulo_emprestimo: 'Empréstimo para Outro Setor',
    aba_alteracoes_tecnicos_responsaveis: 'Alterações de Técnicos Responsáveis',
    aba_historico_movimentacao: 'Movimentação para Outro Setor',
    aba_historico_emprestimo: 'Empréstimos para Outro Setor',
    aba_detalhe_movimentacao: 'Detalhes da Movimentação',
    aba_detalhe_alteracao: 'Detalhes da Alteração',
    aba_detalhe_emprestimo: 'Detalhes do Empréstimo',
    add_movimentacao: 'Nova Movimentação para Outro Setor',
    add_emprestimo: 'Novo Empréstimo para Outro Setor',
    cadastro_sucesso: 'Alteração salva com sucesso!',
    tabela: {
      instrumento: 'Instrumento',
      setor_origem: 'Setor de Origem',
      setor_destino: 'Setor de Destino',
      data_emprestimo: 'Data de Empréstimo',
      data_retorno: 'Data de Retorno',
      data_movimentacao: 'Data de Movimentação',
    },
    form: {
      instrumento: 'Instrumento',
      setor_origem: 'Setor de Origem',
      setor_destino: 'Setor de Destino',
      usuario: 'Usuário',
      data_emprestimo: 'Data de Empréstimo',
      ocorrencia: 'Ocorrência',
      usuario_retorno: 'Usuário de Retorno',
      data_retorno: 'Data de Retorno',
      observacoes: 'Observações',
      data_movimento: 'Data de Movimentação',
      colaborador_atual: 'Colaborador Atual',
      colaborador_destino: 'Colaborador de Destino',
      data_alteracao: 'Data de Alteração',
      data_registro: 'Data de Registro',
      instrumento_sem_setor: 'Nenhum Setor Vínculado',
    },
  },
  modal_inativar_instrumento: {
    titulo: 'Inativar Instrumento',
    confirmacao: 'Confirma a inativação do instrumento ',
    associar_ocorrencia: 'Você pode associar uma Ocorrência à inativação:'
  },
  historico: {
    dataHora: 'Data e Hora',
    usuario: 'Usuário',
    acao: 'Ação',
    envioMaterial: 'Envio de Material',
    ordem_servico: 'Ordem de Serviço',


  }
};

import Swal from 'sweetalert2/dist/sweetalert2.all.min.js';
import i18n from '@/i18n';
const confirmarMixin = Swal.mixin({
  customClass: {
    container: 'swal-customizado v-application',
    confirmButton:
      'botao-padrao v-btn v-btn--has-bg theme--light v-size--default primary',
    cancelButton:
      'botao-padrao v-btn v-btn--outlined theme--light v-size--default danger--secondary',
    denyButton:
      'botao-padrao v-btn v-btn--outlined theme--light v-size--default danger--secondary mr-2',
    closeButton:
      'botao-padrao v-btn v-btn--text theme--light v-size--default secondary--text mr-2',
  },
  buttonsStyling: false,
});

export const AlertasMixin = {
  methods: {
    confirmarExcluir: function () {
      return this.confirmar(
        i18n.t('geral.alertas.excluir.titulo'),
        i18n.t('geral.alertas.excluir.mensagem')
      );
    },
    confirmarSalvar: function () {
      return this.confirmar(
        i18n.t('geral.alertas.salvar.titulo'),
        i18n.t('geral.alertas.salvar.mensagem')
      );
    },
    confirmarCancelar: function () {
      return this.confirmar(
        i18n.t('geral.alertas.cancelar.titulo'),
        i18n.t('geral.alertas.cancelar.mensagem')
      );
    },
    confirmarVoltar: function () {
      return this.confirmar(
        i18n.t('geral.alertas.voltar.titulo'),
        i18n.t('geral.alertas.voltar.mensagem')
      );
    },
    confirmarLiberarOrcamento: function (codigoOrcamento) {
      return this.confirmar(
        i18n.t('geral.alertas.liberar_orcamento.titulo'),
        i18n.t('geral.alertas.liberar_orcamento.mensagem', {
          orcamento: codigoOrcamento,
        })
      );
    },
    confirmarLiberarOrcamentoLote: function (codigos) {
      return this.confirmar(
        i18n.t('geral.alertas.liberar_orcamento_lote.titulo'),
        i18n.t('geral.alertas.liberar_orcamento_lote.mensagem', { codigos })
      );
    },
    confirmarAbrirOrcamento: function () {
      return this.confirmar(
        i18n.t('geral.alertas.abrir_orcamento.titulo'),
        i18n.t('geral.alertas.abrir_orcamento.mensagem')
      );
    },
    confirmarEntrega: function () {
      return this.confirmar(
        i18n.t('geral.alertas.confirmar_entrega.titulo'),
        i18n.t('geral.alertas.confirmar_entrega.mensagem')
      );
    },
    confirmarGerarOrcamentoOS: function (codigo) {
      return this.confirmar(
        i18n.t('geral.alertas.confirmar_gerar_orcamento.titulo'),
        i18n.t('geral.alertas.confirmar_gerar_orcamento.mensagem', { codigo })
      );
    },
    confirmarEnviarEmailLote: function (codigos) {
      return this.confirmar(
        i18n.t('geral.alertas.confirmar_email_lote.titulo'),
        i18n.t('geral.alertas.confirmar_email_lote.mensagem', { codigos })
      );
    },
    confirmarAprovarOrcamento: function (codigo, valor) {
      return this.confirmar(
        i18n.t('geral.alertas.aprovar_orcamento.titulo'),
        i18n.t('geral.alertas.aprovar_orcamento.mensagem', { codigo, valor })
      );
    },
    confirmarReprovarOrcamento: function () {
      return this.confirmar(
        i18n.t('geral.alertas.reprovar_orcamento.titulo'),
        i18n.t('geral.alertas.reprovar_orcamento.mensagem')
      );
    },
    confirmarAdicionarAutomaticamenteServicosPecas: function () {
      return this.confirmar(
        i18n.t('geral.alertas.adicionar_automaticamente_servicos_pecas.titulo'),
        i18n.t(
          'geral.alertas.adicionar_automaticamente_servicos_pecas.mensagem'
        )
      );
    },
    confirmarAgruparInstrumentos: function () {
      return this.confirmar(
        i18n.t('geral.alertas.agrupar_instrumentos.titulo'),
        i18n.t('geral.alertas.agrupar_instrumentos.mensagem')
      );
    },
    confirmarPreAssinarContrato: function () {
      return this.confirmar(
        i18n.t('geral.alertas.pre_assinar_contrato.titulo'),
        i18n.t('geral.alertas.pre_assinar_contrato.mensagem')
      );
    },
    confirmarRedefinirOrcamento: function () {
      return this.confirmar(
        i18n.t('geral.alertas.redefinir_orcamento.titulo'),
        i18n.t('geral.alertas.redefinir_orcamento.mensagem')
      );
    },
    confirmarRedefinirContrato: function () {
      return this.confirmar(
        i18n.t('geral.alertas.redefinir_contrato.titulo'),
        i18n.t('geral.alertas.redefinir_contrato.mensagem')
      );
    },
    confirmarCriarAditivoContrato: function (contrato) {
      return this.confirmar(
        i18n.t('geral.alertas.criar_aditivo_contrato.titulo'),
        i18n.t('geral.alertas.criar_aditivo_contrato.mensagem', { contrato })
      );
    },
    confirmarCriarAditivoContratoJaAditivo: function (contrato, sequencia) {
      return this.confirmar(
        i18n.t('geral.alertas.criar_aditivo_contrato_ja_aditivo.titulo'),
        i18n.t('geral.alertas.criar_aditivo_contrato_ja_aditivo.mensagem', {
          contrato,
          sequencia,
        })
      );
    },
    confirmarDuplicarOrcamento: function () {
      return this.confirmar(
        i18n.t('geral.alertas.duplicar_orcamento.titulo'),
        i18n.t('geral.alertas.duplicar_orcamento.mensagem')
      );
    },
    confirmarDuplicarContrato: function () {
      return this.confirmar(
        i18n.t('geral.alertas.duplicar_contrato.titulo'),
        i18n.t('geral.alertas.duplicar_contrato.mensagem')
      );
    },
    confirmarLiberarContrato: function () {
      return this.confirmar(
        i18n.t('geral.alertas.liberar_contrato.titulo'),
        i18n.t('geral.alertas.liberar_contrato.mensagem')
      );
    },
    confirmarAssinarContrato: function (contrato, valor) {
      return this.confirmar(
        i18n.t('geral.alertas.assinar_contrato.titulo'),
        i18n.t('geral.alertas.assinar_contrato.mensagem', { contrato, valor })
      );
    },
    confirmarReprovarContrato: function (contrato, valor) {
      return this.confirmar(
        i18n.t('geral.alertas.reprovar_contrato.titulo'),
        i18n.t('geral.alertas.reprovar_contrato.mensagem', { contrato, valor })
      );
    },
    confirmarAlteracaoTipoChecklist: function () {
      return this.confirmar(
        i18n.t('geral.alertas.alteracao_checklist.titulo'),
        i18n.t('geral.alertas.alteracao_checklist.mensagem')
      );
    },
    confirmarAlteracaoClienteParticipante: function () {
      return this.confirmar(
        i18n.t('geral.alertas.cliente_participante.titulo'),
        i18n.t('geral.alertas.cliente_participante.mensagem')
      );
    },
    confirmarMarcarComoEnviado: function (mensagem) {
      return this.confirmar(
        i18n.t('geral.alertas.marcar_como_enviado.titulo'),
        mensagem
      );
    },
    confirmarTrocaLocal: function () {
      return this.confirmar(
        i18n.t('geral.alertas.analise_tecnica.titulo_validacao_local'),
        i18n.t('geral.alertas.analise_tecnica.mensagem_validacao_local')
      );
    },
    confirmarTrocaTipoCalibracao: function () {
      return this.confirmar(
        i18n.t(
          'geral.alertas.analise_tecnica_tipo_calibracao.titulo_validacao_tipo_calibracao'
        ),
        i18n.t(
          'geral.alertas.analise_tecnica_tipo_calibracao.mensagem_validacao_tipo_calibracao'
        )
      );
    },
    confirmarValidacaoUnicidadeInstrumento: function (codigo) {
      return this.confirmar(
        i18n.t('geral.alertas.unicidade_instrumento.titulo'),
        i18n.t('geral.alertas.unicidade_instrumento.mensagem', { codigo }),
        false,
        false
      );
    },
    confirmarExcluirPontoCalibracao: function () {
      return this.confirmar(
        i18n.t('geral.alertas.excluir_ponto_calibracao.titulo'),
        i18n.t('geral.alertas.excluir_ponto_calibracao.mensagem')
      );
    },
    confirmar: function (
      titulo,
      mensagem,
      showDenyButton = true,
      showCloseButton = true,
      botaoConfirmar = i18n.t('geral.botoes.sim'),
      botaoCancelar = i18n.t('geral.botoes.nao'),
      input = { type: null, valor: null, texto: null },
      allowOutsideClick = false
    ) {
      return new Promise((resolve, reject) => {
        confirmarMixin
          .fire({
            title: titulo,
            text: mensagem,
            showDenyButton: showDenyButton,
            showCloseButton: showCloseButton,
            reverseButtons: true,
            confirmButtonText: botaoConfirmar,
            denyButtonText: botaoCancelar,
            input: input.type,
            inputValue: input.valor,
            inputPlaceholder: input.texto,
            allowOutsideClick: allowOutsideClick
          })
          .then((result) => {
            if (result.isConfirmed) {
              resolve(result);
            } else {
              reject();
            }
          });
      });
    },
    mensagemConfirmacao: function (
      titulo,
      mensagem,
      botaoConfirmar = i18n.t('geral.botoes.ok'),
      html = false
    ) {
      return new Promise((resolve, reject) => {
        confirmarMixin
          .fire({
            title: titulo,
            text: html ? null : mensagem,
            html: !html ? null : mensagem,
            showDenyButton: false,
            showCloseButton: false,
            reverseButtons: false,
            confirmButtonText: botaoConfirmar,
          })
          .then((result) => {
            if (result.isConfirmed) {
              resolve();
            } else {
              reject();
            }
          });
      });
    },

    /** metodos toast */
    toast: function (configuracao) {
      this.$store.dispatch('Layout/mostrarToast', configuracao);
    },
    toastSucesso: function (mensagem) {
      this.toast({
        mensagem: mensagem,
        tipo: 'success',
      });
    },
    toastErro: function (mensagem) {
      this.toast({
        mensagem: mensagem,
        tipo: 'error',
      });
    },
    toastAlerta: function (mensagem) {
      this.toast({
        mensagem: mensagem,
        tipo: 'warning',
      });
    },
  },
};

export default {
  titulos: {
    listagem: 'Procedimentos Eletrônicos',
    novo: 'Novo Procedimento Eletrônico',
    editar: 'Editar Procedimento Eletrônico - {codigo}',
    visualizar: 'Visualizar Revisão - Procedimento Eletrônico - {codigo}',
    novo_sem_tabela: 'Novo Procedimento Eletrônico - {codigo}',
    certificado: 'Visualizar certificado',
    visualizar_revisao:
      'Visualizar Revisões - Procedimento Eletrônico {procedimento_eletronico}',
  },
  usuarios: {
    usuarios_signatarios: 'Usuários Signatários',
    tecnicos_executantes: 'Usuários Técnicos Executantes',
    usuario: 'Usuário',
    email: 'E-mail',
    login: 'Login',
    nome: 'Nome',
    papel: 'Papel',
    associar_usuarios: 'Associar Usuários Signatários',
    associar_tecnicos: 'Associar Técnicos Executantes',
  },
  cadastro_sucesso:
    'Procedimento salvo com sucesso.  Agora é possível inserir uma tabela de registro.',
  duplicar_sucesso: 'Sequência %{SEQUENCIA} criada com sucesso',
  copiar_procedimento_eletronico_sucesso:
    'O procedimento eletrônico %{PROC} foi criado para o cliente %{CLIENTE}.',
  duplicar_procedimento_eletronico_sucesso:
    'Procedimento %{PROC} criado com sucesso',
  edicao_sucesso: 'Procedimento salvo com sucesso.',
  exclusao_sucesso: 'Procedimento eletrônico excluído com sucesso!',
  exclusao_revisao_sucesso: 'Revisão excluída com sucesso!',
  certificado_salvo: 'Certificado salvo com sucesso!',
  filtro: {
    codigo: 'Código',
    nome: 'Nome',
    revisao: 'Revisão Publicada',
    local: 'Local',
  },
  tabela: {
    codigo: 'Código',
    revisao: 'Revisão',
    nome: 'Nome',
    local: 'Local',
    revisao_publicada: 'Revisão Publicada',
    publicada_em: 'Publicada em',
    criado_em: 'Criado em',
    usuario_criacao: 'Usuário de Criação',
    usuario_publicacao: 'Usuário de Publicação',
    justificativa: 'Justificativa',
  },
  confirmar_mudanca_aba: {
    titulo: 'Atenção',
    mensagem:
      'Há dados não salvos neste formulário, deseja salvar as alterações? ',
  },
  formulario: {
    abas: {
      dados_procedimento: 'Dados do Procedimento',
      tabela_registro: 'Tabelas de Registros',
      clique_salvar_continuar: 'Clique para Salvar e Continuar',
      bloqueada:
        'É necessário salvar os dados do procedimento antes de adicionar uma tabela de registro.',
    },
    revisao: 'Revisão',
    nome: 'Nome',
    tipo_procedimento: 'Tipo do Procedimento',
    tipo_instrumento: 'Tipo de Instrumento',
    tipo_calibracao: 'Tipo da Calibração',
    escopo_servico: 'Escopo do Serviço',
    participantes: 'Participantes',
    local: 'Local de Execução',
    data_conferencia: 'Data da Última Conferência',
    intervalo_conferencias: 'Intervalo entre Conferências (meses)',
    proxima_conferencia: 'Próxima Conferência',
    notasCertificado: 'Notas do Certificado',
    justificativa: 'Justificativa',
    confirmar_alteracao_dados:
      'Ao alterar os escopos de serviços, os campos Tipo de Calibração e Local de Execução terão seu conteúdo apagados. Deseja continuar?',
    tabela: {
      titulo: 'Participantes e Setores',
      participantes: 'Participante',
      setor: 'Setor',
    },
  },
  erros: {
    id_invalido: 'O id do procedimento eletrônico é invalido',
    participante_selecionado: 'O participante já esta incluido na tabela',
    necessario_campo_tabela_auxiliar: 'É necessário informar a coluna da tabela auxiliar',
  },
  validacoes: {
    formulario_invalido:
      'É necessário preencher o formulário antes de salvar o registro.',
    tabela_formulario_invalido:
      'É necessário preencher a tabela de registro antes de salvar o registro.',
    coluna: 'É necessário informar pelo menos uma coluna',
    colunas_smp_smc:
      'A tabela de registro não pode ter somente uma coluna dos tipos SMC e SMP respectivamente',
  },
  botoes: {
    visualizar_revisoes: 'Visualizar Revisões',
    publicar: 'Publicar',
    duplicar: 'Duplicar',
    desenhar_certificado: 'Certificado',
    copiar_procedimento_cliente: 'Criar cópia para cliente',
    associar_usuarios: 'Associar Usuários',
  },
  alertas: {
    certificado:
      'A revisão selecionada já está publicada. Deseja realmente criar uma nova Revisão e editar este modelo de certificado?',
    procedimento:
      'A revisão selecionada já está publicada. Deseja realmente criar uma nova Revisão e editar este procedimento eletrônico?',
    justificativa: 'É necessário informar a justificativa para publicar a revisão',
  },
  tabela_registro: {
    confirmar_alteracao_dados:
      'Ao alterar o campo de {campo}, as colunas da tabela abaixo serão automaticamente apagadas. Deseja continuar?',
    resolucao_smc: 'Resolução do SMC',
    resolucao_smp: 'Resolução do SMP variável {variavel}',
    titulos: {
      novo: 'Nova Tabela de Registro',
      editar: 'Editar Tabela de Registro',
      visualizar: 'Visualizar Tabela de Registro',
    },
    cadastro_sucesso: 'Tabela de registro salva com sucesso!',
    exclusao_sucesso: 'Tabela de registro excluída com sucesso!',
    tabela_salva: 'Tabela de registro salva com sucesso!',
    tabela_erro: 'É preciso cadastrar pelo menos uma tabela de registro.',
    botao_adicionar: 'Nova Tabela de Registro',
    tabela: {
      sequencia: 'Sequência',
      nome: 'Nome',
      faixa: 'Faixa',
    },
    formulario: {
      arredondamento_smc: 'Arredondamento do SMC',
      nome: 'Nome',
      tipo_calibracao: 'Tipo de Calibração',
      grandeza: 'Grandeza',
      unidade_medida: 'Unidade de Medida',
      amplitude: 'Amplitude',
      amplitude_min: 'Mínima',
      formula_mensurando: 'Fórmula do Mensurando',
      amplitude_max: 'Máxima',
      procedimento: 'Procedimento',
      incerteza: 'Incerteza',
      instrumentos_padroes: 'Instrumentos Padrão',
      sistema_sexagesimal: 'Sistema Sexagesimal',
      campos_adicionais: 'Campos Adicionais',
      formula_arredondamento_smc: 'Fórmula de Correção do SMC',
      eh_tabela_auxiliar: 'É uma Tabela Auxiliar?',
      tabela_auxiliar: 'Tabela Auxiliar',
      pontos_calibracao: 'Pontos de Calibração',
      apresentar_certificado: 'Apresentar no Certificado?',
      tipos_calibracoes: {
        instrumento: 'Instrumento',
        unico: 'Único',
      }
    },
    reordenar_colunas: 'Reordenar Colunas',
  },
  publicar: {
    titulo: 'Publicar',
    mensagem: 'Deseja realmente publicar revisão?',
    sucesso: 'Revisão publicada com sucesso',
  },
  tabela_dinamica: {
    modal_formula: 'Fórmula da Coluna',
    botoes: {
      adicionar_coluna: 'Adicionar coluna',
      adicionar_linha: 'Adicionar linha',
    },
    linhas_fixas: {
      visivel: 'Visível',
      variavel: 'Variável',
      unidade: 'Unidade',
      cabecalho: 'Cabeçalho',
      sentido: 'Sentido/Direção',
      formula: 'Fórmula',
      tipo_calculo: 'Tipo do calculo',
    },
  },
  modal_participantes: {
    titulo: 'Participantes',
    tabela: {
      apelido:'Apelido',
      nome: 'Nome',
      setor: 'Setor',
    },
    filtro: {
      nome: 'Nome',
    },
  },
};

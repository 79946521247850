<template lang="">
  <div>
    <div class="d-flex align-end mt-2">
      <input-text
        v-model="filtro.colaboradorResponsavel"
        :label="$t('componentes.input_ocorrencia_instrumento.colaborador')"
        class="flex-fill mr-6"
      />
      <input-text
        v-model="filtro.ocorrenciaPadrao"
        :label="$t('componentes.input_ocorrencia_instrumento.ocorrencia')"
        class="flex-fill mr-6"
      />
      <botao-padrao
        outlined
        color="secondary"
        @click="filtrar"
      >
        {{ $t('geral.botoes.filtrar') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      v-model="selecionados"
      :dados="tabela.dados"
      class="mt-4"
      :colunas="tabela.colunas"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      :mostrar-acoes="false"
      :selecionar-apenas-um="multiplos"
      :mostra-header="false"
      @paginar="listar"
    />
  </div>
</template>
<script>
import RegistroOcorrenciaService from '@common/services/cadastros/RegistroOcorrenciaService';
import helpers from '@common/utils/helpers';

import _ from 'lodash';

export default {
  props: {
    multiplos: { type: Boolean, default: true },
    value: { type: Array, default: () => [] },
    instrumentoId: { type: String, default: null },
  },
  data() {
    return {
      filtro: {
        instrumento: '',
        colaboradorResponsavel: '',
        ocorrenciaPadrao: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'data',
            text: this.$t(
              'componentes.input_ocorrencia_instrumento.tabela.data'
            ),
            sortable: false,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v);
            },
          },
          {
            value: 'colaboradorResponsavel.nome',
            text: this.$t(
              'componentes.input_ocorrencia_instrumento.tabela.colaborador'
            ),
            sortable: false,
          },
          {
            value: 'ocorrenciaPadrao.nome',
            text: this.$t(
              'componentes.input_ocorrencia_instrumento.ocorrencia'
            ),
            sortable: false,
          },
          {
            value: 'observacao',
            text: this.$t(
              'componentes.input_ocorrencia_instrumento.tabela.observacao'
            ),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },

  watch: {
    value: {
      handler() {
        this.selecionados = this.value;
      },
      immediate: true,
    },
    selecionados: {
      handler() {
        this.$emit('input', this.selecionados);
      },
    },
  },

  methods: {
    filtrar: function () {
      this.filtroAplicado = _.cloneDeep(this.filtro);
      this.listar();
    },

    listar: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        Page: paginaAtual,
        AmountPerPage: porPagina,
        ...this.filtroAplicado,
      };

      this.$store.dispatch('Layout/iniciarCarregamento');

      if (this.instrumentoId) parametros.instrumentoId = this.instrumentoId;
      else return this.$store.dispatch('Layout/terminarCarregamento'); // Só busca se tiver instrumentoId por prop

      RegistroOcorrenciaService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>

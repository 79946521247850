export default {
  erroGenerico: 'Ocorreu um erro ao realizar operação',
  maximo100CaracteresParaUmItemDoCampoAdicional: 'Máximo de 100 caracteres para um item do campo Itens',
  maximo100CaracteresParaValorPadrao: 'Máximo de 100 caracteres para um item do campo Valor Padrão',
  frequencia_preencher: 'O campo Frequência deve ser preenchido',
  instrumento_contrato_associado: 'O Instrumento não tem um Contrato Associado',
  participante_preencher: 'O campo Cliente deve ser preenchido',
  criterio_aceitacao_preencher:
    'O campo Critério de aceitação deve ser preenchido',
  pontos_calibracao_preencher: 'O campo Pontos a Calibrar deve ser preenchido',
  data_ultima_calibracao_preencher:
    'O campo Data Última Calibração deve ser preenchido',
  descricao_preencher: 'O campo Descrição deve ser preenchido',
  descricao_preencher_item_detalhe:
    'Nos serviços e/ou Peças o campo Descrição deve ser preenchido ',
  preencha_data_parcelas:
    'Para ser concluído o calculo de calibrações, preencha a data de ínicio e as parcelas',
  preencha_frequencia_data:
    'Para ser concluído o calculo de calibrações, preencha a frequência e a última calibração',
  prazo_entrega_preencher: 'O campo Prazo deve ser preenchido',
  prazo_entrega_preencher_item_detalhe:
    'Nos serviços e/ou Peças o campo Prazo deve ser preenchido ',
  valor_unitario_preencher: 'O campo Valor do Serviço deve ser preenchido',
  valor_unitario_preencher_item_detalhe:
    'Nos serviços e/ou Peças o campo Valor Serviço deve ser preenchido ',
  valor_total_preencher: 'O campo Valor Total deve ser preenchido',
  calibracaoTabelasDevePertenceAoMesmoProcedimento: 'Tabelas de Calibração devem pertencer ao mesmo procedimento',
  valor_total_preencher_item_detalhe:
    'Nos serviços e/ou Peças o campo Valor Total deve ser preenchido ',
  quantidade_preencher: 'O campo Quantidade deve ser preenchido',
  quantidade_preencher_item_detalhe:
    'Nos serviços e/ou Peças o campo Quantidade deve ser preenchido',
  selecione_item: 'Selecione algum item',
  preencher_faixa: 'Preencha a Faixa dos Instrumentos',
  ProximoSetorNaoEncontrado:
    'Próximo Setor do Tipo de Instrumento não encontrado',
  cliente_possui_criterios_aprovacao:
    'Este Cliente já possui Critérios de Aprovação',
  insira_servico_peca: 'Por favor insira algum serviço ou peça',
  insira_escopo_contrato: 'Por favor insira algum Escopo de Contrato',
  sequenciaSetorNaoEncontrada: 'Sequência de Setores não encontrada',
  setorAtualNaoEncontrado: 'Setore atual não encontrado',
  proximoSetorNaoEncontradoNaListaDeSequenciaDeSetores:
    'Próximo Setor não encontrado na lista de Sequência de Setores',
  insira_itens_orcamento: 'Por favor insira algum Item de Orçamento',
  setorInicialNaoCadastrado:
    'Tipo do Instrumento deve ter sequência de setores cadastrado',
  selecione_item_detalhe: 'Selecione algum serviço/peça',
  undefined: 'Ocorreu um erro ao realizar operação',
  tabelasAuxiliaresDevemSerPrimeirasSequencias: 'Tabelas auxiliares devem vir primeiro',
  tabelaColunaAuxiliarInexistente: 'Necessário adicionar uma coluna auxiliar',
  badRequest: 'Houve uma falha ao tentar fazer login. Tente novamente!',
  naoAutorizado: 'Login ou senha incorreta',
  expiredToken: 'Sessão expirada. Faça o login novamente!',
  OrcamentoNaoEncontrado: 'Orçamento não encontrado',
  deveSerInformadoPeloMenos1Variavel:
    'A formula deve ter pelo menos um simbolo!',
  OrcamentoComentarioNaoEncontrado: 'Comentário não encontrado',
  clienteCriterioAprovacaoOrcamentoNaoEncontrado:
    'Criterio de Aprovação não encontrado!',
  naoEPermitidoExcluirUmComentarioQueNaoEOUltimoDoRelacionamento:
    'Não é possível excluir esse comentário',
  municipioNaoEncontrado: 'Município não encontrado',
  acreditacaoNaoEncontrada: 'Acreditação não encontrada',
  localExecucaoNaoEncontrado: 'Local de execução não encontrado',
  tipoCalibracaoInexistente: 'Tipo de calibração inexistente',
  unidadeMedidaInexistente: 'Unidade de medida inexistente',
  participanteContaInexistente: 'Participante com conta inexistente',
  instrumentoNaoEncontrado: 'Instrumento não encontrado',
  ordemServicoNaoEncontrada: 'Ordem de serviço não encontrada',
  tipoCalibracaoNaoEncontrado: 'Tipo de calibração não encontrado',
  servicoNaoEncontrado: 'Serviço não encontrado',
  colaboradorDestinoNaoEncontrado: 'Colaborador de destino não encontrado',
  colaboradorOrigemNaoEncontrado: 'Colaborador de origem não encontrado',
  registroOcorrenciaNaoEncontrado: 'Registro de ocorrência não encontrado',
  participanteDeveSerInformado: 'O participante deve ser informado',
  Maximo500CaracteresParaNome: 'Máximo de 500 caracteres para Nome',
  participanteNaoDeveSerInformado: 'O participante não deve ser informado',
  participanteInexistente: 'Participante inexistente',
  participanteDeveSerDoTipoCliente: 'O participante deve ser do tipo cliente',
  tipoInstrumentoInexistente: 'Tipo de instrumento inexistente',
  instrumentoInexistente: 'Instrumento inexistente',
  setorInexistente: 'Setor inexistente',
  setorOrigemNaoEncontrado: 'Setor de origem não encontrado',
  setorDestinoNaoEncontrado: 'Setor de destino não encontrado',
  setoresDosInstrumentosDaOrdemServicoDiferentes:
    'Setores dos Instrumentos da Ordem de Serviço são diferentes',
  setorInstrumentoNaoEMesmoSetorOrigem:
    'O setor do instrumento não é o mesmo setor de origem',
  faixaNaoEncontrada: 'Faixa não encontrada',
  valorForaDaFaixa: 'Valor fora da faixa',
  modeloInstrumentoInexistente: 'Modelo de instrumento inexistente',
  setorDoInstrumentoNaOrdemDeServicoNaoEncontrado:
    'Setor do Instrumento na Ordem de Serviço não encontrado',
  tipoItemInexistente: 'Tipo de item inexistente',
  categoriaItemInexistente: 'Categoria de item inexistente',
  clausulaContratualNaoEncontrada: 'Cláusula contratual não encontrada',
  tipoSetorObrigatorio: 'O tipo de setor é obrigatório',
  relatorioNaoEncontrado: 'Relatório não encontrado',
  statusDeveSerAguardandoAprovacaoOuEmElaboracao:
    'O status deve ser "Aguardando Aprovação" ou "Em Elaboração"',
  participanteFabricanteInexistente: 'Participante fabricante inexistente',
  classificacaoFiscalInexistente: 'Classificação fiscal inexistente',
  localEstocagemInexistente: 'Local de estocagem inexistente',
  codigoDoItemDeveSerUnico: 'O código do item deve ser único',
  participanteNaoEncontrado: 'Participante não encontrado',
  participanteDeveSerFornecedorLaboratorio:
    'O participante deve ser fornecedor/laboratório',
  unidadeMedidaNaoEncontrada: 'Unidade de medida não encontrada',
  codigoJaUtilizado: 'Código já utilizado',
  modeloIdRepetido: 'ID do modelo repetido',
  procedimentoInexistente: 'Procedimento inexistente',
  responsavelSetorNaoExiste: 'Responsável pelo setor não existe',
  areaAcreditacaoInexistente: 'Área de acreditação inexistente',
  classificacaoInstrumentoNaoExiste: 'Classificação do instrumento não existe',
  pontoNaoEncontrado: 'Ponto não encontrado',
  usuarioNaoEncontrado: 'Usuário não encontrado',
  participanteInvalidoNoAcesso: 'Participante inválido no acesso',
  classificacaoInstrumentoInexistente:
    'Classificação do instrumento inexistente',
  checklistDeveSerUnico:
    'Já existe um checklist cadastrado para esse Tipo de Instrumento e/ou Classificação de Instrumento',
  pecaInexistente: 'Peça inexistente',
  servicoInexistente: 'Serviço inexistente',
  cargoFuncaoInexistente: 'Cargo/função inexistente',
  marcaInexistente: 'Marca inexistente',
  setorOrigemInexistente: 'Setor de origem inexistente',
  setorDestinoInexistente: 'Setor de destino inexistente',
  ocorrenciaInexistente: 'Ocorrência inexistente',
  tipoOcorrenciaNaoEncontrado: 'Tipo de ocorrência não encontrado',
  colaboradorNaoEncontrado: 'Colaborador não encontrado',
  tipoSetorInexistente: 'Tipo de setor inexistente',
  checklistNaoEncontrado: 'Checklist não encontrado',
  criterioAceitacaoNaoEncontrado: 'Critério de aceitação não encontrado',
  participanteClienteNaoEncontrado: 'Participante cliente não encontrado',
  emprestimoOutroSetorNaoEncontrado:
    'Empréstimo para outro setor não encontrado',
  setorNaoEncontrado: 'Setor não encontrado',
  tipoInstrumentoNaoEncontrado: 'Tipo de instrumento não encontrado',
  tipoInstrumentoSetorNaoEncontrado:
    'Tipo de instrumento do setor não encontrado',
  modeloInstrumentoNaoEncontrado: 'Modelo de instrumento não encontrado',
  marcaNaoEncontrada: 'Marca não encontrada',
  itemNaoEncontrado: 'Item não encontrado',
  ocorrenciaPadraoNaoEncontrado: 'Ocorrência padrão não encontrada',
  procedimentoEletronicoNaoEncontrado: 'Procedimento eletrônico não encontrado',
  grandezaFisicaInexistente: 'Grandeza física inexistente',
  instrumentoPadraoInexistente: 'Instrumento padrão inexistente',
  participanteClienteInexistente: 'Participante cliente inexistente',
  calibracaoNaoEncontrada: 'Calibração não encontrada',
  localExecucaoInexistente: 'Local de execução inexistente',
  usuarioTecnicoExecutanteInexistente:
    'Por favor, associe um usuário técnico executante',
  incertezaNaoEncontrada: 'Incerteza não encontrada',
  ordemServicoInexistente: 'Ordem de serviço inexistente',
  unidadeMedidaDevePertencerGrandezaFisica:
    'A unidade de medida deve pertencer à grandeza física',
  unidadeMedidaColunaInexistente: 'Unidade de medida (coluna) inexistente',
  incertezaInexistente: 'Incerteza inexistente',
  tabelaNaoEncontrada: 'Tabela não encontrada',
  participanteClienteOrigemInexistente:
    'Participante cliente de origem inexistente',
  participanteClienteContatoInexistente:
    'Contato do participante cliente inexistente',
  participanteClienteContatoIdObrigatorio:
    'ID do contato do participante cliente obrigatório',
  solicitanteParticipanteInexistente: 'Solicitante participante inexistente',
  tipoInstrumentoNaoExiste: 'Tipo de instrumento não existe',
  movimentacaoNaoEncontrada: 'Movimentação não encontrada',
  emailDoUsuarioDeveSerUnico: 'O email do usuário deve ser único',
  papelNaoEncontrado: 'Papel não encontrado',
  incertezasComFaixasConcomitantes: 'Incertezas com faixas concomitantes',
  embalagemInexistente: 'Embalagem inexistente',
  tipoEnvioInexistente: 'Tipo de envio inexistente',
  prioridadeInexistente: 'Prioridade inexistente',
  ordemServicoNaoEncontrado: 'Ordem de serviço não encontrada',
  participanteLogadoNaoPodeSerVazio: 'O participante logado não pode ser vazio',
  tabelaIdObrigatorio: 'ID da tabela obrigatório',
  enviarEmailObrigatorio: 'Enviar email obrigatório',
  procedimentoEletronicoIdObrigatorio:
    'ID do procedimento eletrônico obrigatório',
  nomeObrigatorio: 'Nome obrigatório',
  maximo50CaracteresParaNome: 'Máximo de 50 caracteres para o nome',
  revisaoNaoEncontrada: 'Revisão não encontrada',
  incertezaPossuiRevisaoPublicada: 'A incerteza possui revisão publicada',
  veiculoInexistente: 'Veículo inexistente',
  maximo20CaracteresParaCodigo: 'Máximo de 20 caracteres para o código',
  codigoObrigatorio: 'Código obrigatório',
  maximo150CaracteresParaNome: 'Máximo de 150 caracteres para o nome',
  maximo1000CaracteresParaDescricao:
    'Máximo de 1000 caracteres para a descrição',
  tipoServicoObrigatorio: 'Tipo de serviço obrigatório',
  tipoServicoInvalido: 'Tipo de serviço inválido',
  preencherTipoCalibracao: 'Preencher tipo de calibração',
  localExecucaoObrigatorio: 'Local de execução obrigatório',
  minimoPontosObrigatorio: 'Mínimo de pontos obrigatório',
  minimoPontosDeveSerMaiorIgualZero:
    'Mínimo de pontos deve ser maior ou igual a zero',
  tempoExecucaoObrigatorio: 'Tempo de execução obrigatório',
  tempoExecucaoDeveSerMaiorIgualZero:
    'Tempo de execução deve ser maior ou igual a zero',
  valorPontoObrigatorio: 'Valor do ponto obrigatório',
  valorHoraObrigatorio: 'Valor da hora obrigatório',
  valorHoraDeveSerMaiorIgualZero:
    'Valor da hora deve ser maior ou igual a zero',
  valorServicoObrigatorio: 'Valor do serviço obrigatório',
  valorServicoDeveSerMaiorIgualZero:
    'Valor do serviço deve ser maior ou igual a zero',
  documentoDeveConterSomenteNumeros: 'O documento deve conter apenas números',
  documentoObrigatorio: 'Documento obrigatório',
  cnpjInvalido: 'CNPJ inválido',
  maximo20CaracteresParaoInscricaoEstadual:
    'Máximo de 20 caracteres para a inscrição estadual',
  maximo20CaracteresParaInscricaoMunicipal:
    'Máximo de 20 caracteres para a inscrição municipal',
  papelFornecedorObrigatorio: 'Papel do fornecedor obrigatório',
  participanteDeveTerSomenteUmEnderecoTipoSede:
    'O participante deve ter somente um endereço do tipo sede',
  participanteDeveTerUmEnderecoTipoSede:
    'O participante deve ter um endereço do tipo sede',
  descricaoDeveSerPreenchido: 'Descrição deve ser preenchido',
  maximo50CaracteresParaCodigo: 'Máximo de 50 caracteres para código',
  categoriaItemObrigatorio: 'Categoria do item obrigatório',
  tipoItemObrigatorio: 'Tipo do item obrigatório',
  valorVendaInvalido: 'Valor de venda inválido',
  maximo2CaracteresCom18CasasDecimaisParaValorVenda:
    'Máximo de 2 caracteres com 18 casas decimais para valor de venda',
  codigoMaxCaracteresInvalido:
    'Código com número máximo de caracteres inválido',
  tituloMaxCaracteresInvalido:
    'Título com número máximo de caracteres inválido',
  descricaoMaxCaracteresInvalido:
    'Descrição com número máximo de caracteres inválido',
  maximo100CaracteresParaDescricao: 'Máximo de 100 caracteres para descrição',
  maximo200CaracteresParaDescricao: 'Máximo de 200 caracteres para descrição',
  aliquotaDeveEstarEntre0a100: 'Alíquota de IPI deve estar entre 0 a 100',
  mesInvalido: 'Mês inválido',
  anoObrigatorio: 'Ano obrigatório',
  unidadeFederativaNaoDeveSerInformadaParaNivelFederal:
    'A unidade federativa não deve ser informada para o nível federal',
  municipioNaoDeveSerInformadaParaNivelFederal:
    'O município não deve ser informado para o nível federal',
  unidadeFederativaObrigatorioParaNivelEstadual:
    'Unidade federativa obrigatória para o nível estadual',
  municipioNaoDeveSerInformadaParaNivelEstadual:
    'O município não deve ser informado para o nível estadual',
  unidadeFederativaObrigatoriaParaNivelMunicipal:
    'Unidade federativa obrigatória para o nível municipal',
  municipioObrigatorioParaNivelMunicipal:
    'Município obrigatório para o nível municipal',
  pontoIdObrigatorio: 'ID do ponto obrigatório',
  valorMaximaObrigatorio: 'Valor máximo obrigatório',
  maximo250CracteresParaDescricao: 'Máximo de 250 caracteres para descrição',
  maximo500CaracteresParaNome: 'Máximo de 500 caracteres para nome',
  marcaObrigatoria: 'Marca obrigatória',
  valorDeveSerMaiorOuIgualA0: 'O valor deve ser maior ou igual a 0',
  valorDeveTerNoMaximo2CaracteresCom18CasasDecimais:
    'O valor deve ter no máximo 2 caracteres com 18 casas decimais',
  unidadeMedidaIdObrigatoria: 'ID da unidade de medida obrigatória',
  maximo1000CaracteresParaDescricaoCompleta:
    'Máximo de 1000 caracteres para descrição completa',
  participanteResponsavelFaturamentoNaoPodeSerEleMesmo:
    'O participante responsável pelo faturamento não pode ser ele mesmo',
  descricacaoMaxCaracteresInvalido:
    'Descrição com número máximo de caracteres inválido',
  codigoInvalido: 'Código inválido',
  alterarResultadosObrigatorio: 'Alterar resultados obrigatório',
  maximo7CaracteresParaCodigoEspecificadorST:
    'Máximo de 7 caracteres para código de especificador ST',
  maximo5CaracteresParaCodigoServicoLeiComplementar:
    'Máximo de 5 caracteres para código de serviço da lei complementar',
  maximo5CaracteresCom2CasasDecimaisParaValorFrete:
    'Máximo de 5 caracteres com 2 casas decimais para valor do frete',
  maximo1000CaracteresParaOcorrencia:
    'Máximo de 1000 caracteres para ocorrência',
  prazoEntregaNaoPodeSerMenorQueZero:
    'O prazo de entrega não pode ser menor que zero',
  maximo2000CaracteresParaDescricao: 'Máximo de 2000 caracteres para descrição',
  maximo500CaracteresObservacao: 'Máximo de 500 caracteres para observação',
  setorDestinoNaoPoderSerOMesmoDaOrigem:
    'O setor de destino não pode ser o mesmo da origem',
  maximo7CaracteresCom18CasasDecimaisParaValorCompra:
    'Máximo de 7 caracteres com 18 casas decimais para valor de compra',
  maximo2CaracteresCom18CasasDecimaisParaValorFrete:
    'Máximo de 2 caracteres com 18 casas decimais para valor do frete',
  campoNomeObrigatorio: 'Campo nome obrigatório',
  tituloObrigatorio: 'Título obrigatório',
  maximo200CaracteresParaTitulo: 'Máximo de 200 caracteres para título',
  classificacaoInvalida: 'Classificação inválida',
  dataInformadaMaiorQueAtual: 'Data informada maior que a atual',
  campoDescricaoObrigatorio: 'Campo descrição obrigatório',
  colaboradorInexistente: 'Colaborador inexistente',
  maxCaracteresInvalido: 'Número máximo de caracteres inválido',
  maximo50CaracteresParaNotaFiscal: 'Máximo de 50 caracteres para nota fiscal',
  participanteOrigemNaoExiste: 'Participante de origem não existe',
  nomeDoUsuarioNaoPodeSerVazio: 'O nome do usuário não pode ser vazio',
  emailDoUsuarioNaoPodeSerVazio: 'O e-mail do usuário não pode ser vazio',
  emailDoUsuarioDeveSerValido: 'O e-mail do usuário deve ser válido',
  senhaDoUsuarioNaoPodeSerVazio: 'A senha do usuário não pode ser vazia',
  deveSerInformadoPeloMenos1Participante:
    'Deve ser informado pelo menos 1 participante',
  idDoPapelNaoPodeSerVazio: 'O ID do papel não pode ser vazio',
  emailConfirmacaoNaoPodeSerVazio: 'A confirmação de e-mail não pode ser vazia',
  confirmacaoDeEmailNaoConfere: 'A confirmação de e-mail não confere',
  AnaliseTecnicaFinalizada: 'Análise Técnica Finalizada',
  senhaConfirmacaoNaoPodeSerVazio: 'A confirmação de senha não pode ser vazia',
  confirmacaoDeSenhaNaoConfere: 'A confirmação de senha não confere',
  idDoUsuarioNaoPodeSerVazio: 'O ID do usuário não pode ser vazio',
  campoRequerido: 'Campo requerido',
  minCaracteresInvalido: 'Número mínimo de caracteres inválido',
  apenasNumerosPermitidos: 'Apenas números são permitidos',
  codigoJaExistente: 'Código já existente',
  modeloInstrumentoJaExistente: 'Modelo de instrumento já cadastrado',
  clienteInexistente: 'Cliente inexistente',
  clienteDeveSerParticipanteCliente: 'Cliente deve ser participante cliente',
  classificacaoInstrumentoPaiInexistente:
    'Classificação de instrumento pai inexistente',
  unidadeFederativaInexistente: 'Unidade Federativa inexistente',
  classificacaoFiscalNaoEncontrada: 'Classificação fiscal não encontrada',
  tipoItemNaoEncontrado: 'Tipo de item não encontrado',
  valorInstrumentoObrigatorio: 'Valor do instrumento obrigatório',
  maximo2CaracteresCom19CasasDecimaisParaValorServico:
    'Máximo de 2 caracteres com 19 casas decimais para valor do serviço',
  descontoObrigatorio: 'Desconto obrigatório',
  preencherAmplitudeMaxima: 'Preencher amplitude máxima',
  preencherAmplitudeMinima: 'Preencher amplitude mínima',
  maximo19CaracteresCom9CasasDecimaisParaAmplitudeMaxima:
    'Máximo de 19 caracteres com 9 casas decimais para amplitude máxima',
  maximo19CaracteresCom9CasasDecimaisParaAmplitudeMinima:
    'Máximo de 19 caracteres com 9 casas decimais para amplitude mínima',
  descontoDeveEstarEntre0a100: 'O desconto deve estar entre 0 e 100',
  preencherUnidadeMedidaId: 'Preencher o ID da unidade de medida',
  usuarioInvalido: 'Usuário inválido',
  identificacaoObrigatorio: 'Identificação obrigatória',
  maximoIdentificacao200Caractes: 'Máximo de 200 caracteres para identificação',
  maximo200CaracteresParaNome: 'Máximo de 200 caracteres para nome',
  permissaoInexistente: 'Permissão inexistente',
  tipoPontoInvalido: 'Tipo de ponto inválido',
  papelAdministradorNaoPodeSerExcluido:
    'O papel de administrador não pode ser excluído',
  acreditacaoObrigatoria: 'Acreditação obrigatória',
  resolucaoDeveSerMaior0: 'A resolução deve ser maior que 0',
  maximo14CaracteresParaCPF: 'Máximo de 14 caracteres para CPF',
  cpfInvalido: 'CPF inválido',
  incertezaIdNaoPodeSerVazio: 'O ID de incerteza não pode ser vazio',
  estruturaUtilizadaInvalida: 'Estrutura utilizada inválida',
  maximo200Caracteres: 'Máximo de 200 caracteres',
  maximo100CaracteresParaNome: 'Máximo de 100 caracteres para nome',
  generoInvalido: 'Gênero inválido',
  tipoProcedimentoInvalido: 'Tipo de procedimento inválido',
  deveInformarPeloMenos1Coluna: 'Deve informar pelo menos 1 coluna',
  maximo5ColunasDoTipoColunaTexto: 'Máximo de 5 colunas do tipo texto',
  maximo1ColunaDoTipoColunaReferencia: 'Máximo de 1 coluna do tipo referência',
  deveInformarPeloMenos1Incerteza: 'Deve informar pelo menos 1 incerteza',
  maximo1000CaracteresDescricaoPadrao:
    'Máximo de 1000 caracteres para descrição padrão',
  maximo500CaracteresJustificativa:
    'Máximo de 500 caracteres para justificativa',
  justificativaObrigatoriaParaStatus: 'Justificativa obrigatória para o status',
  dataStatusObrigatorioParaStatus: 'Data do status obrigatória para o status',
  faixaIdObrigatorio: 'ID da faixa obrigatório',
  maximo1000CaracteresDescricaoMelhorArtefato:
    'Máximo de 1000 caracteres para descrição do melhor artefato',
  idObrigatorio: 'ID obrigatório',
  paraDuplicarOContratoOParticipanteIdEObrigadorio:
    'Para duplicar o contrato, o ID do participante é obrigatório',
  idClienteObrigatorio: 'ID do cliente obrigatório',
  opcaoInvalida: 'Opção inválida',
  maximo100CracteresParaNome: 'Máximo de 100 caracteres para nome',
  maximo250CaracteresParadescricao: 'Máximo de 250 caracteres para descrição',
  nomeDeveConterMenos200Caracteres:
    'O nome deve conter menos de 200 caracteres',
  instrumentoIdObrigatorio: 'ID do instrumento obrigatório',
  maximo200CaracteresParaAssinatura: 'Máximo de 200 caracteres para assinatura',
  maximo200CaracteresParaEmailCorporativo:
    'Máximo de 200 caracteres para e-mail corporativo',
  papelNaoPodeSerExcluidoUsuarioRelacionado:
    'O papel não pode ser excluído, pois está relacionado a usuários',
  toleranciaLimiteInferiorDeveSerMaior0:
    'A tolerância do limite inferior deve ser maior que 0',
  toleranciaLimiteSuperiorDeveSerMaior0:
    'A tolerância do limite superior deve ser maior que 0',
  componenteIdNaoPodeSerVazio: 'O ID do componente não pode ser vazio',
  descricaoObrigatoria: 'Descrição obrigatória',
  oPrimeiroSetorDeveSerObrigatoriamenteDeUmTipoDeSetorComPosicaoIgualInicial:
    'O primeiro setor deve ser obrigatoriamente de um tipo de setor com posição igual a inicial',
  oPrimeiroSetorDeveSerObrigatoriamenteDeUmTipoDeSetorComPosicaoIgualFinal:
    'O primeiro setor deve ser obrigatoriamente de um tipo de setor com posição igual a final',
  nomeArquivoObrigatorio: 'Nome do arquivo obrigatório',
  origemDadoInvalido: 'Origem do dado inválido',
  deveInformarPeloMenosDuasFormulasErroIncerteza:
    'Deve ser Informado pelo menos duas Fórmulas',
  incertezaDadoIdObrigatorio: 'ID da incerteza do dado obrigatório',
  formulaDadoDeveSerVazia: 'A fórmula do dado deve ser vazia',
  formulaDadoObrigatorio: 'Fórmula do dado obrigatória',
  maximo1000CaracteresParaFormulaDado:
    'Máximo de 1000 caracteres para a fórmula do dado',
  incertezaDadoIdDeveSerVazio: 'O ID da incerteza do dado deve ser vazio',
  unidadeMedidaDadoIdObrigatorio: 'ID da unidade de medida do dado obrigatório',
  distribuicaoEstatisticaInvalida: 'Distribuição estatística inválida',
  formulaDEObrigatoria: 'Fórmula DE obrigatória',
  maximo1000CaracteresParaFormulaDE:
    'Máximo de 1000 caracteres para a fórmula DE',
  unidadeMedidaCiIdObrigatorio: 'ID da unidade de medida CI obrigatório',
  unidadeMedidaIncertezaPadraoIdObrigatorio:
    'ID da unidade de medida de incerteza padrão obrigatório',
  deveSerInformadoApenasMetodoNumericoCiOuFormulaCi:
    'Deve ser informado apenas o método numérico CI ou a fórmula CI',
  maximo1000CaracteresParaFormulaCi:
    'Máximo de 1000 caracteres para a fórmula CI',
  maximo200CaracteresParaMetodoNumericoCi:
    'Máximo de 200 caracteres para o método numérico CI',
  unidadeMedidaObrigatorio: 'Unidade de medida obrigatória',
  unidadeMedidaCMCObrigatorio: 'Unidade de medida CMC obrigatória',
  amplitudeMinimaObrigatorio: 'Amplitude mínima obrigatória',
  amplitudeMaximaObrigatorio: 'Amplitude máxima obrigatória',
  amplitudeMaximaDeveSerMaiorOuIgualAAmplitudeMinima:
    'A amplitude máxima deve ser maior ou igual à amplitude mínima',
  maximo100CaracteresParaFormulaCMC:
    'Máximo de 100 caracteres para a fórmula CMC',
  maximo19CaracteresCom9CasasDecimaisParaValorCMCInicial:
    'Máximo de 19 caracteres com 9 casas decimais para o valor CMC inicial',
  maximo19CaracteresCom9CasasDecimaisParaValorCMCFinal:
    'Máximo de 19 caracteres com 9 casas decimais para o valor CMC final',
  preencherFormulaCMC: 'Preencher fórmula CMC',
  preencherValorCMCInicial: 'Preencher valor CMC inicial',
  preencherValorCMCFinal: 'Preencher valor CMC final',
  paraTipoCMCValorFixoOuPercentualValorCMCInicialDeveSerIgualValorCMCFinal:
    'Para o tipo CMC de valor fixo ou percentual, o valor CMC inicial deve ser igual ao valor CMC final',
  valorCMCFinalDeveSerMaiorOuIgualAValorInicial:
    'O valor CMC final deve ser maior ou igual ao valor inicial',
  maximo50CaracteresParaDescricao: 'Máximo de 50 caracteres para a descrição',
  nivelObrigatorio: 'Nível obrigatório',
  nivelInvalido: 'Nível inválido',
  tipoObrigatorio: 'Tipo obrigatório',
  tipoInvalido: 'Tipo inválido',
  diaObrigatorio: 'Dia obrigatório',
  diaInvalido: 'Dia inválido',
  mesObrigatorio: 'Mês obrigatório',
  maximo150CaracteresParaDescricao: 'Máximo de 150 caracteres para a descrição',
  maximo500CaracteresParaDescricaoCompleta:
    'Máximo de 500 caracteres para a descrição completa',
  maximo500CaracteresParaNomeExtensaoImagem:
    'Máximo de 500 caracteres para o nome da extensão da imagem',
  maximo256CaracteresParaNomeArquivo:
    'Máximo de 256 caracteres para o nome do arquivo',
  flagPadraoObrigatorio: 'Flag padrão obrigatório',
  participanteClienteIdObrigatorio: 'ID do participante cliente obrigatório',
  maximo100CaracteresParaCodigoTag:
    'Máximo de 100 caracteres para o código de tag',
  codigoTagObrigatorio: 'Código de tag obrigatório',
  maximo100CaracteresPraNumeroSerie:
    'Máximo de 100 caracteres para o número de série',
  tipoInstrumentoIdObrigatorio: 'ID do tipo de instrumento obrigatório',
  flagUtilizadoEmCampoObrigatorio: 'Flag utilizado em campo obrigatório',
  maximo2CaracteresCom18CasasDecimaisParaValorInstrumento:
    'Máximo de 2 caracteres com 18 casas decimais para o valor do instrumento',
  flagRevisadoPelaUnidadeNegocioMantenedoraObrigatorio:
    'Flag revisado pela unidade de negócio mantenedora obrigatório',
  maximo2000CaracteresObservacao: 'Máximo de 2000 caracteres para a observação',
  flagAtivoObrigatorio: 'Flag ativo obrigatório',
  modeloInstrumentoIdObrigatorio: 'ID do modelo de instrumento obrigatório',
  maximo150CaracteresDescricao: 'Máximo de 150 caracteres para a descrição',
  descricaoObrigatorio: 'Descrição obrigatória',
  unidadeMedidaIdObrigatorio: 'ID da unidade de medida obrigatório',
  valorObrigatorio: 'Valor obrigatório',
  resolucaoObrigatorio: 'Resolução obrigatória',
  maximo150CaracteresParaApelido: 'Máximo de 150 caracteres para o apelido',
  maximo20CaracteresParaTelefone: 'Máximo de 20 caracteres para o telefone',
  maximo200CaracteresParaEmail: 'Máximo de 200 caracteres para o email',
  emailInvalido: 'Email inválido',
  emailClienteNaoCadastrado: 'Email do cliente não cadastrado',
  maximo200CaracteresParaoSite: 'Máximo de 200 caracteres para o site',
  maximo1000CaracteresParaAcaoCorretiva:
    'Máximo de 1000 caracteres para a ação corretiva',
  municipioInexistente: 'Município inexistente',
  incertezaDadoIdNaoPodeSerIgualAIncertezaId:
    'O ID do dado de incerteza não pode ser igual ao ID de incerteza',
  movimentacaoNaoEncontrado: 'Movimentação não encontrada',
  unidadeFederativaNaoEncontrada: 'Unidade Federativa não encontrada',
  revisaoJaFoiPublicada: 'A revisão já foi publicada',
  componenteNaoEncontrado: 'Componente não encontrado',
  revisaoDoComponenteJaFoiPublicada: 'A revisão do componente já foi publicada',
  metodoNumericoCiObrigatorio: 'Método numérico Ci obrigatório',
  formulaCiObrigatorio: 'Fórmula Ci obrigatória',
  incertezaDadoInexistente: 'Dado de incerteza inexistente',
  revisaoDaIncertezaJaPublicada: 'A revisão da incerteza já foi publicada',
  toleranciaLimiteSuperiorDeveSerMaiorQueToleranciaLimiteInferior:
    'A tolerância limite superior deve ser maior que a tolerância limite inferior',
  amplitudeMaximaDeveSerMaior0: 'A amplitude máxima deve ser maior que 0',
  amplitudeMinimaDeveSerMaior0: 'A amplitude mínima deve ser maior que 0',
  jaExistePapelComEsseNome: 'Já existe um papel com esse nome',
  papelNaoPodeSerInativadoUsuarioRelacionado:
    'O papel não pode ser inativado, pois está relacionado a usuários',
  apenasNumerosParaCodigo: 'Apenas números para o código',
  maximo2000CaracteresParaDescricaoCompleta:
    'Máximo de 2000 caracteres para a descrição completa',
  aliquotaIpiDeveSerMaiorOuIgualAZero:
    'A alíquota de IPI deve ser maior ou igual a zero',
  maximoDe7CaracteresCom2CasasDecimaisParaAliquotaIpi:
    'Máximo de 7 caracteres com 2 casas decimais para a alíquota de IPI',
  maximo10CaracteresParaCodigo: 'Máximo de 10 caracteres para o código',
  participanteContaIdObrigatorio: 'ID da conta do participante obrigatório',
  maximo500CaracteresParaDescricao: 'Máximo de 500 caracteres para a descrição',
  maximoDe15CaracteresCom5CasasDecimaisParaValor:
    'Máximo de 15 caracteres com 5 casas decimais para o valor',
  tipoInstrumentoObrigatorio: 'Tipo de instrumento obrigatório',
  localExecucaoIdNaoEncontrado: 'ID de local de execução não encontrado',
  tipoCalibracaoIdNaoEncontrado: 'ID de tipo de calibração não encontrado',
  participanteClienteIdNaoEncontrado:
    'ID do participante cliente não encontrado',
  incertezaNaoTemRevisaoNaoPublicada:
    'A incerteza não possui revisão não publicada',
  participanteResponsavelInexistente: 'Participante responsável inexistente',
  grandezaFisicaInexistentegrandezaFisicaInexistente:
    'Grandeza física inexistente',
  maximo2CaracteresCom5CasasDecimaisParaDesconto:
    'Máximo de 2 caracteres com 5 casas decimais para o desconto',
  maximo2CaracteresCom19CasasDecimaisParaValorHora:
    'Máximo de 2 caracteres com 19 casas decimais para o valor hora',
  valorPontoDeveSerMaiorIgualZero:
    'O valor do ponto deve ser maior ou igual a zero',
  maximo2CaracteresCom19CasasDecimaisParaValorPonto:
    'Máximo de 2 caracteres com 19 casas decimais para o valor do ponto',
  esteTipoServicoNaoPreencheTipoCalibracao:
    'Este tipo de serviço não preenche o tipo de calibração',
  ordemServicoJaPossuiCalibracaoParaEsseInstrumento:
    'A ordem de serviço já possui calibração para esse instrumento',
  incertezaNaoPossuiUmaRevisaoNaoPublicada:
    'A incerteza não possui uma revisão não publicada',
  procedimentoEletronicoNaoPossuiUmaRevisaoNaoPublicada:
    'O procedimento eletrônico não possui uma revisão não publicada',
  revisaoPublicadaNaoPodeSerRemovida:
    'A revisão publicada não pode ser removida',
  amplitudeMaximaDeveSerMaiorQueAmplitudeMinima:
    'A amplitude máxima deve ser maior que a amplitude mínima',
  maximoDe19CaracteresCom9CasasDecimaisParaAmplitudeMinima:
    'Máximo de 19 caracteres com 9 casas decimais para a amplitude mínima',
  maximoDe19CaracteresCom9CasasDecimaisParaAmplitudeMaxima:
    'Máximo de 19 caracteres com 9 casas decimais para a amplitude máxima',
  procedimentoEletronicoNaoTemRevisaoNaoPublicada:
    'O procedimento eletrônico não tem revisão não publicada',
  deveInformarUnidadeMedidaIdAmplitudeMinimaAmplitudeMaximaQuandoPreenchidoPeloMenosUm:
    'Deve informar o ID da unidade de medida, amplitude mínima e amplitude máxima quando preenchido pelo menos um',
  participanteFornecedorCalibracaoNaoEncontrado:
    'Participante fornecedor de calibração não encontrado',
  participanteEResponsavelFaturamentoDeveDessaociarOsParticipantesRelacionados:
    'O participante e o responsável pelo faturamento devem desassociar os participantes relacionados',
  colaboradorResponsavelInstrumentoNaoEncontrado:
    'Colaborador responsável pelo instrumento não encontrado',
  existemModelosInstrumentosNaoAssociadosATipoInstrumentoPassado:
    'Existem modelos de instrumentos não associados ao tipo de instrumento passado',
  jaExisteUmEmprestimoEmAndamentoParaEsteInstrumentoParaOSetorDesejado:
    'Já existe um empréstimo em andamento para este instrumento para o setor desejado',
  orcamentoNaoEncontrado: 'Orçamento não encontrado',
  contratoNaoEncontrado: 'Contrato não encontrado',
  tipoComentarioOrcamentoNaoEncontrado:
    'Tipos de comentário do orçamento não encontrado',
  orcamentoJaPossuiRevisao: 'O orçamento já possui revisão',
  trocaDeStatusDeOrcamentoNaoPermitida:
    'Troca de status de orçamento não permitida',
  trocaDeStatusDeContratoNaoPermitida:
    'Troca de status de contrato não permitida',
  calibracaoIdDeveSerInformado: 'O ID da calibração deve ser informado',
  deveSerInformadoPeloMenos1Tabela: 'Deve ser informado pelo menos uma tabela',
  tabelaNaoPermitida: 'Tabela não é permitida',
  colaboradorIdObrigatorio: 'ID do colaborador obrigatório',
  dataEmissaoObrigatorio: 'Data de emissão obrigatória',
  prazoGarantiaObrigatorio: 'Prazo de garantia obrigatório',
  flagEmElaboracaoObrigatorio: 'Flag em elaboração obrigatória',
  numeroParcelaObrigatorio: 'Número de parcela obrigatório',
  flagCondicaoPagamentoPorContratoObrigatorio:
    'Flag condição de pagamento por contrato obrigatória',
  maximo100CaracteresParaNumeroPedidoCompra:
    'Máximo de 100 caracteres para o número do pedido de compra',
  formaSolicitacaoOrcamentoObrigatorio:
    'Forma de solicitação de orçamento obrigatória',
  formaSolicitacaoOrcamentoInvalida:
    'Forma de solicitação de orçamento inválida',
  maximo100CaracteresParaDescricaoFormaSolicitacaoOrcamento:
    'Máximo de 100 caracteres para a descrição da forma de solicitação de orçamento',
  localExecucaoIdObrigatorio: 'ID do local de execução obrigatório',
  tipoDescontoObrigatorio: 'Tipo de desconto obrigatório',
  tipoDescontoInvalido: 'Tipo de desconto inválido',
  maximo2000CaracteresParaObservacao:
    'Máximo de 2000 caracteres para a observação',
  valorTotalItensObrigatorio: 'Valor total dos itens obrigatório',
  maximoDe15CaracteresCom4CasasDecimaisParaValorTotalItens:
    'Máximo de 15 caracteres com 4 casas decimais para o valor total dos itens',
  valorTotalDescontoObrigatorio: 'Valor total do desconto obrigatório',
  maximoDe15CaracteresCom4CasasDecimaisParaValorTotalDesconto:
    'Máximo de 15 caracteres com 4 casas decimais para o valor total do desconto',
  valorTotalFreteObrigatorio: 'Valor total do frete obrigatório',
  maximoDe15CaracteresCom4CasasDecimaisParaValorTotalFrete:
    'Máximo de 15 caracteres com 4 casas decimais para o valor total do frete',
  valorTotalOutrasDespesasObrigatorio:
    'Valor total de outras despesas obrigatório',
  maximoDe15CaracteresCom4CasasDecimaisParaValorTotalOutrasDespesas:
    'Máximo de 15 caracteres com 4 casas decimais para o valor total de outras despesas',
  numeroItemObrigatorio: 'Número do item obrigatório',
  prazoEntregaObrigatorio: 'Prazo de entrega obrigatório',
  quantidadeObrigatoria: 'Quantidade obrigatória',
  maximoDe15CaracteresCom2CasasDecimaisParaQuantidade:
    'Máximo de 15 caracteres com 2 casas decimais para a quantidade',
  valorUnitarioObrigatorio: 'Valor unitário obrigatório',
  maximoDe15CaracteresCom2CasasDecimaisParaValorUnitario:
    'Máximo de 15 caracteres com 2 casas decimais para o valor unitário',
  valorTotalObrigatorio: 'Valor total obrigatório',
  maximoDe15CaracteresCom2CasasDecimaisParaValorTotal:
    'Máximo de 15 caracteres com 2 casas decimais para o valor total',
  maximoDe15CaracteresCom2CasasDecimaisParaDesconto:
    'Máximo de 15 caracteres com 2 casas decimais para o desconto',
  valorFreteObrigatorio: 'Valor do frete obrigatório',
  maximoDe15CaracteresCom2CasasDecimaisParaValorFrete:
    'Máximo de 15 caracteres com 2 casas decimais para o valor do frete',
  outrasDepesasObrigatorio: 'Outras despesas obrigatórias',
  maximoDe15CaracteresCom2CasasDecimaisParaOutrasDepesas:
    'Máximo de 15 caracteres com 2 casas decimais para o valor de outras despesas',
  quantidadeAprovadaObrigatorio: 'Quantidade aprovada obrigatória',
  maximoDe15CaracteresCom2CasasDecimaisParaQuantidadeAprovada:
    'Máximo de 15 caracteres com 2 casas decimais para a quantidade aprovada',
  mensagemOrcamentoIdObrigatoria: 'ID da mensagem do orçamento obrigatório',
  participanteClienteContatoNaoEncontrado:
    'Contato do cliente participante não encontrado',
  condicaoPagamentoNaoEncontrada: 'Condição de pagamento não encontrada',
  participanteFornecedorNaoEncontrado: 'Fornecedor participante não encontrado',
  itemInexistente: 'Item inexistente',
  mensagemInexistente: 'Mensagem inexistente',
  participantesClientesNaoPodeSerVazio:
    'Participantes clientes não podem ser vazios',
  tiposCalibracoesNaoPodeSerVazio: 'Tipos de calibrações não podem ser vazios',
  statusObrigatorio: 'Status obrigatório',
  statusInvalido: 'Status inválido',
  calibracaoTabelasDevePertencerAoMesmoProcedimento:
    'Tabelas de calibração devem pertencer ao mesmo procedimento',
  periodoRevisaoMesesDeveSerMaiorOuIgual0:
    'O período de revisão em meses deve ser maior ou igual a 0',
  certificadoObrigatorio: 'Certificado obrigatório',
  certificadoTamanhoDeveSerMaior0:
    'O tamanho do certificado deve ser maior que 0',
  certificadoExtensaoInvalida: 'Extensão de certificado inválida',
  certificadoNaoEncontrado: 'Certificado não encontrado',
  revisaoIdObrigatorio: 'ID de revisão obrigatório',
  ordemServicoInstrumentoSituacaoInvalida:
    'Situação inválida do instrumento na ordem de serviço',
  ordemServicoIdObrigatorio: 'ID da ordem de serviço obrigatório',
  mensagemNaoEncontrada: 'Mensagem não encontrada',
  calibracaoLinhaIdObrigatorio: 'ID da linha de calibração obrigatório',
  colunaIdObrigatorio: 'ID da coluna obrigatório',
  calibracaoLinhaNaoEncontrada: 'Linha de calibração não encontrada',
  tabelaColunaNaoPermitida: 'Coluna da tabela não permitida',
  colunaIdDuplicada: 'ID de coluna duplicado',
  calibracaoJaFoiFinalizada: 'A calibração já foi finalizada',
  procedimentoEletronicoNaoEncontrada: 'Procedimento eletrônico não encontrado',
  escoposNaoEncontrada: 'Escopos não encontrados',
  incertezaRevisaoNaoEncontrada: 'Incerteza de revisão não encontrada',
  erroAoCalcularAMedia: 'Erro ao calcular a média',
  erroAoCalcularOErro: 'Erro ao calcular o erro',
  erroAoCalcularODesvioPadrao: 'Erro ao calcular o desvio padrão',
  erroAoCalcularOValorDE: 'Erro ao calcular o valor DE',
  erroAoCalcularOValorCi: 'Erro ao calcular o valor Ci',
  erroAoCalcularOValorDado: 'Erro ao calcular o valor dado',
  erroAoCalcularOValor: 'Erro ao calcular o valor',
  erroAoCalcularAIncertezaCombinada: 'Erro ao calcular a incerteza combinada',
  erroAoCalcularOGrausLiberdadeEfetivos:
    'Erro ao calcular os graus de liberdade efetivos',
  erroAoCalcularOFatorK: 'Erro ao calcular o fator K',
  erroAoCalcularAIncertezaExpandidaCalculada:
    'Erro ao calcular a incerteza expandida calculada',
  erroAoCalcularAIncertezaExpandidaArredondada:
    'Erro ao calcular a incerteza expandida arredondada',
  certificadoNaoPodeSerAssinado: 'O certificado não pode ser assinado',
  flagLidoObrigatorio: 'Flag lido obrigatório',
  aprovacaoOrcamentoObrigatorioItensAprovados:
    'A aprovação do orçamento é obrigatória para itens aprovados',
  certificadoNaoPodeSerCancelado: 'O certificado não pode ser cancelado',
  usuarioSignatarioInexistente: 'Por favor, associe um usuário signatário',
  orcamentoObrigatorio: 'Orçamento obrigatório',
  tipoOrcamentoComentarioObrigatorio:
    'Tipos de comentário do orçamento obrigatório',
  usuarioCriacaoIdObrigatorio: 'ID do usuário de criação obrigatório',
  comentarioObrigatorio: 'Comentário obrigatório',
  tipoOrcamentoComentarioNaoEncontrado:
    'Tipos de comentário do orçamento não encontrado',
  orcamentoComentarioNaoEncontrado: 'Comentário do orçamento não encontrado',
  usuarioNaoPodeExcluirComentario: 'Usuário não pode excluir o comentário',
  usuarioNaoPodeAlterarComentario: 'Usuário não pode alterar o comentário',
  simboloObrigatorio: 'Símbolo obrigatório',
  maximo5CaracteresParaSimbolo: 'Máximo de 5 caracteres para o símbolo',
  formulaObrigatorio: 'Fórmula obrigatória',
  maximo1000CaracteresParaFormula: 'Máximo de 1000 caracteres para a fórmula',
  formulaPaiIdObrigatorio: 'ID da fórmula pai obrigatório',
  formulaPaiInexistente: 'Fórmula pai inexistente',
  unidadeMedidaVariavelInexistente: 'Unidade de medida da variável inexistente',
  erroAoRealizarAValidacaoDoCalculo: 'Erro ao realizar a validação do cálculo',
  maximoDe15CaracteresCom9CasasDecimaisParaValorTeste:
    'Máximo de 15 caracteres com 9 casas decimais para o valor de teste',
  formulaNaoEncontrada: 'Fórmula não encontrada',
  variavelSimboloDuplicada: 'Símbolo de variável duplicado',
  formulaPossuiFormulasFilhas: 'A fórmula possui fórmulas filhas',
  formulaVariavelNaoEncontrada: 'Variável da fórmula não encontrada',
  formulaMensurandoIdObrigatorio: 'ID da fórmula de mensurando obrigatório',
  formulaMensurandoNaoEncontrada: 'Fórmula de mensurando não encontrada',
  tipoMaterialInvalido: 'Tipo de material inválido',
  formulaEmUsoNaTabelaRegistro: 'Fórmula em uso na tabela de registro',
  formulaEmUsoNaTabelaRegistroColuna:
    'Fórmula em uso na coluna da tabela de registro',
  formulaPossuiFormulaSistemaEmUsoNaTabelaRegistro:
    'A fórmula possui fórmula do sistema em uso na tabela de registro',
  formulaVariavelEmUso: 'Variável da fórmula em uso',
  formulaSistemaDeVariavelEmUso: 'Sistema de variável da fórmula em uso',
  participanteIdObrigatorio: 'ID do participante obrigatório',
  tipoContratoObrigatorio: 'Tipo de contrato obrigatório',
  tipoContratoInvalido: 'Tipo de contrato inválido',
  dataInicioObrigatorio: 'Data de início obrigatória',
  valorMensalObrigatorio: 'Valor mensal obrigatório',
  maximoDe15CaracteresCom2CasasDecimaisParaValorMensal:
    'Máximo de 15 caracteres com 2 casas decimais para o valor mensal',
  valorItensObrigatorio: 'Valor dos itens obrigatório',
  tipoColunaInvalido: 'Tipo de coluna inválido',
  cabecalhoObrigatorio: 'Cabeçalho obrigatório',
  maximo50CaracteresParaCabecalho: 'Máximo de 50 caracteres para o cabeçalho',
  sentidoInvalido: 'Sentido inválido',
  variavelSomentePodeSerAssociadoAColunasDoTipoSMPOuReferencia:
    'A variável só pode ser associada a colunas do tipo SMP ou Referência',
  formulaSomentePodeSerAssociadoAColunasDoTipoResultado:
    'A fórmula só pode ser associada a colunas do tipo Resultado',
  maximoDe19CaracteresCom9CasasDecimaisParaValorReferencia:
    'Máximo de 19 caracteres com 9 casas decimais para o valor de referência',
  maximo20CaracteresParaValorTexto1:
    'Máximo de 20 caracteres para o valor de texto 1',
  maximo20CaracteresParaValorTexto2:
    'Máximo de 20 caracteres para o valor de texto 2',
  maximo20CaracteresParaValorTexto3:
    'Máximo de 20 caracteres para o valor de texto 3',
  maximo20CaracteresParaValorTexto4:
    'Máximo de 20 caracteres para o valor de texto 4',
  maximo20CaracteresParaValorTexto5:
    'Máximo de 20 caracteres para o valor de texto 5',
  variavelNaoPertenceFormula: 'A variável não pertence à fórmula',
  entidadeObrigatoria: 'Entidade obrigatória',
  tipoCampoObrigatorio: 'Tipo de campo obrigatório',
  valorPadraoDeveSerNumerico: 'O valor padrão deve ser numérico',
  valorPadraoDeveSerData: 'O valor padrão deve ser uma data',
  valorPadraoDeveSerBooleano: 'O valor padrão deve ser booleano',
  campoAdicionalInexistente: 'Campo adicional inexistente',
  deveInformarPeloMenos1Id: 'Deve informar pelo menos 1 ID',
  participanteIdDuplicado: 'ID do participante duplicado',
  opcoesContratoInvalida: 'Opções de contrato inválidas',
  cpfTestemunhaInvalido: 'CPF da testemunha inválido',
  maximo200CaracteresNomeTestemunha:
    'Máximo de 200 caracteres para o nome da testemunha',
  instrumentoFaixaInexistente: 'Faixa do instrumento inexistente',
  criteriosAceitacoesFaixasInexistente:
    'Critérios de aceitação das faixas inexistentes',
  criterioAceitacaoFaixaInexistente:
    'Critério de aceitação da faixa inexistente',
  perfilUsuarioObrigatorio: 'Perfil do usuário obrigatório',
  formulaVariavelInexistente: 'Variável da fórmula inexistente',
  tabelaInstrumentoPadraoDeveSerUnico:
    'A tabela do instrumento padrão deve ser única',
  formulaVariavelIdObrigatorio: 'ID da variável da fórmula obrigatório',
  maximoDe15CaracteresCom2CasasDecimaisParaValorItens:
    'Máximo de 15 caracteres com 2 casas decimais para o valor dos itens',
  tipoAcrescimoObrigatorio: 'Tipo de acréscimo obrigatório',
  tipoAcrescimoInvalido: 'Tipo de acréscimo inválido',
  valorAcrescimoObrigatorio: 'Valor do acréscimo obrigatório',
  maximoDe15CaracteresCom4CasasDecimaisParaValorAcrescimo:
    'Máximo de 15 caracteres com 4 casas decimais para o valor do acréscimo',
  valorDescontoObrigatorio: 'Valor do desconto obrigatório',
  maximoDe15CaracteresCom4CasasDecimaisParaValorDesconto:
    'Máximo de 15 caracteres com 4 casas decimais para o valor do desconto',
  valorFinalObrigatorio: 'Valor final obrigatório',
  maximoDe15CaracteresCom2CasasDecimaisParaValorFinal:
    'Máximo de 15 caracteres com 2 casas decimais para o valor final',
  maximo2000CaracteresNomeTestemunha:
    'Máximo de 2000 caracteres para o nome da testemunha',
  maximo14CaracteresCpfTestemunha:
    'Máximo de 14 caracteres para o CPF da testemunha',
  flagAditivoObrigatorio: 'Flag de aditivo obrigatório',
  flagRenovacaoObrigatorio: 'Flag de renovação obrigatório',
  participanteContaIObrigatorio: 'Conta I do participante obrigatória',
  sequenciaObrigatoria: 'Sequência obrigatória',
  instrumentoFaixaIdObrigatorio: 'ID da faixa do instrumento obrigatório',
  frequenciaCalibracaoEmMesesObrigatoria:
    'Frequência de calibração em meses obrigatória',
  quantidadeTotalObrigatoria: 'Quantidade total obrigatória',
  maximoDe15CaracteresCom2CasasDecimaisParaQuantidadeTotal:
    'Máximo de 15 caracteres com 2 casas decimais para a quantidade total',
  instalacaoClienteObrigatoria: 'Instalação do cliente obrigatória',
  dataProximaCalibracaoObrigatoria: 'Data de próxima calibração obrigatória',
  dataUltimaCalibracaoObrigatoria: 'Data da última calibração obrigatória',
  usuarioIdObrigatorio: 'ID do usuário obrigatório',
  dataHoraObrigatoria: 'Data e hora obrigatórias',
  ocorrenciaObrigatorio: 'Ocorrência obrigatória',
  maximo2000CaracteresParaOcorrencia:
    'Máximo de 2000 caracteres para a ocorrência',
  criterioAceitacaoInexistente: 'Critério de aceitação inexistente',
  usuarioInexistente: 'Usuário inexistente',
  criterioAprovacaoOrcamentoObrigatorio:
    'Critério de aprovação de orçamento obrigatório',
  operadorLogicoObrigatorio: 'Operador lógico obrigatório',
  tipoCriterioObrigatorio: 'Tipo de critério obrigatório',
  operadorRelacionalObrigatorio: 'Operador relacional obrigatório',
  tipoValorObrigatorio: 'Tipo de valor obrigatório',
  valorLogicoObrigatorio: 'Valor lógico obrigatório',
  valorNumericoObrigatorio: 'Valor numérico obrigatório',
  valorPercentualObrigatorio: 'Valor percentual obrigatório',
  ordemServicoSituacaoInvalida: 'Situação inválida para a ordem de serviço',
  analiseTecnicaNaoEncontrada: 'Análise técnica não encontrada',
  tamanhoMaximo10: 'Tamanho máximo de 10',
  codigoTamanhoMaximo10: 'Código com tamanho máximo de 10',
  tamanhoMaximo100: 'Tamanho máximo de 100',
  tituloTamanhoMaximo100: 'Título com tamanho máximo de 100',
  descricaoTamanhoMaximo1000: 'Descrição com tamanho máximo de 1000',
  tamanhoMaximo1000: 'Tamanho máximo de 1000',
  endpointsObrigatorio: 'Endpoints obrigatório',
  maximo10CaracteresParaSigla: 'Máximo de 10 caracteres para a sigla',
  maximo15CaracteresCom7CasasDecimaisParaTemperatura:
    'Máximo de 15 caracteres com 7 casas decimais para a temperatura',
  maximo15CaracteresCom7CasasDecimaisParaTemperaturaVariacao:
    'Máximo de 15 caracteres com 7 casas decimais para a variação de temperatura',
  maximo15CaracteresCom7CasasDecimaisParaUmidadeRelativa:
    'Máximo de 15 caracteres com 7 casas decimais para a umidade relativa',
  maximo15CaracteresCom7CasasDecimaisParaUmidadeRelativaVariacao:
    'Máximo de 15 caracteres com 7 casas decimais para a variação de umidade relativa',
  maximo15CaracteresCom7CasasDecimaisParaPressaoAtmosferica:
    'Máximo de 15 caracteres com 7 casas decimais para a pressão atmosférica',
  maximo15CaracteresCom7CasasDecimaisParaPressaoAtmosfericaVariacao:
    'Máximo de 15 caracteres com 7 casas decimais para a variação de pressão atmosférica',
  clienteJaPossuiCriterioAprovacao: 'Cliente já possui critério de aprovação',
  somenteFaixasPadronizadasObrigatorio:
    'Somente faixas padronizadas obrigatório',
  flagTemperaturaRequeridaObrigatorio:
    'Flag de temperatura requerida obrigatório',
  flagPressaoRequeridaObrigatorio: 'Flag de pressão requerida obrigatório',
  flagPermitirColunaAngularObrigatorio:
    'Flag de permitir coluna angular obrigatório',
  flagCalcularResultadoFinalLeituraObrigatorio:
    'Flag de calcular resultado final de leitura obrigatório',
  flagCalcularResultadoManualmenteObrigatorio:
    'Flag de calcular resultado manualmente obrigatório',
  setorIdObrigatorio: 'ID do setor obrigatório',
  drdemObrigatoria: 'Ordem obrigatória',
  flagUmidadeRequeridaObrigatorio: 'Flag de umidade requerida obrigatório',
  formatoDataApenasMesAnoInvalido: 'Formato de data apenas mês/ano inválido',
  frequenciaCalibracaoEmMesesNaoInformadaParaServicoCalibracao:
    'Frequência de calibração em meses não informada para serviço de calibração',
  valorItensNaoInformadoParaServicoCalibracao:
    'Valor dos itens não informado para serviço de calibração',
  proximaCalibracaoNaoInformadoParaServicoCalibracao:
    'Próxima calibração não informada para serviço de calibração',
  variavelResolucaoNaoEncontrada: 'Variável de resolução não encontrada',
  arredondamentoSMCObrigatorio: 'Arredondamento SMC obrigatório',
  variavelResolucaoIdObrigatorio: 'ID da variável de resolução obrigatório',
  arredondamentoSMCDeveSerVazio: 'Arredondamento SMC deve ser vazio',
  variavelResolucaoIdDeveSerVazio: 'ID da variável de resolução deve ser vazio',
  erroAoDuplicarIncerteza: 'Erro ao duplicar incerteza',
  anexoObrigatorio: 'Anexo obrigatório',
  orcamentoAnexoNaoEncontrado: 'Anexo de orçamento não encontrado',
  tipoPontoObrigatorio: 'Tipo de ponto obrigatório',
  origemPontoObrigatorio: 'Origem do ponto obrigatória',
  origemPontoInvalido: 'Origem do ponto inválida',
  campoAdicionalObrigatorio: 'Campo adicional obrigatório',
  campoAdicionalValorInvalido: 'Valor do campo adicional inválido',
  revisaoDaTabelaJaFoiPublicada: 'Revisão da tabela já foi publicada',
  contratoComInstrumentoJaExistente: 'Contrato com instrumento já existente',
  refreshTokenInvalido: 'Token inválido',
  campoObrigatorio: 'O campo é obrigatório',
  simboloPodeTerNoMaximo10Caracteres:
    'Simbolo deve ter no máximo 10 caracteres',
  calibracaoValorNaoEncontrada: 'É necessário salvar a calibração primeiro.',
  valorReferenciaDeveEstarEntre0a100:
    'Valor de referência deve estar entre 0 a 100',
  cepNaoEncontrado: 'CEP não encontrado',
  movimentoInvalidoInstrumentoEmprestado:
    'Ação inválida. Instrumento já se encontra emprestado.',
  movimentoInvalidoInstrumentoNaoEmprestado:
    'Ação inválida. Instrumento não se encontra emprestado.',
  formulaSimboloDeveSerUnicaEntreAsFormulasDoMesmoMensurando:
    'O Símbolo deve ser único entre as Fórmulas do Mensurando.',
  formulaVariavelSimboloNaoPodeSerIgualFormulaSimbolo:
    'O Símbolo da Fórmula não pode ser igual ao Símbolo das Variáveis.',
  dataDevolucaoNaoPodeSerAnteriorQueDataEmprestimo:
    'Data de devolução não deve ser anterior à data de empréstimo',
  criterioAceitacaoJaExistente: 'Critério de aceitação já existe',
  usuarioSolicitanteObrigatorio: 'Usuário solicitante obrigatório',
  dataResolucaoNaoPodeSerAnteriorDataSolicitacao:
    'Data de resolução não deve ser anterior à data de solicitação',
  setorDestinoObrigatorio: 'Setor de Destino obrigatório',
  modoExibicaoDescricaoContratoInvalido: 'Modo de Exibição inválido',
  grauPolinomioMaiorQueNumeroPontosCalibracaoMenos1:
    'Grau do polinômio deve ser menor que o número de pontos calibrados',
  falhaAoCalcularConformidade: 'Falha ao calcular conformidade',
  categoriaInvalida: 'Tipo de Dúvida/Solicitação inválida',
  trocaDeStatusNaoPermitida: 'Troca de Status não permitida',
  derivasNaoFinalizadas: 'Há faixas do instrumento sem definir as derivas',
  conformidadeNaoEncontrada: 'Conformidade não encontrada',
  erroAoCalcularAFormula: 'Erro ao calcular a formula',
  tipoInstrumentoPossuiModeloInstrumentoAssociado:
    'Tipo Instrumento Possui Modelo Instrumento Associado',
  procedimentoEletronicoPossuiRevisaoPublicada:
    'Procedimento eletrônico possui revisão publicada',
  deveTerPeloMenos1ComponenteDoTipoA:
    'Deve existir pelo menos um componente do tipo A',
  emailContatoInvalido: 'Email ou contato inválido',
  maximo2CaracteresCom18CasasDecimaisParaValor:
    'Máximo 2 caracteres com 18 casas decimais para valor',
  campoAdicionalPossuiModelosInstrumentosRelacionados:
    'O campo adicional está relacionado a algum Modelo de Instrumento',
  campoAdicionalPossuiInstrumentosRelacionados:
    'O campo adicional está relacionado a algum Instrumento',
  preenchaDadosdoUsuario: 'Preencha os dados de Usuário do Sistema',
  escopoCodigoJaExistente: 'Código de escopo de serviço já existente',
  acreditacaoCodigoJaExistente: 'Código de acreditação já existente',
  instrumentoSituacaoInvalida: 'Situação do instrumento inválida',
  loginDoUsuarioDeveSerUnico: 'Login do usuário deve ser único',
  necessarioSempreInformarUmEnderecoDeCadaTipo:
    'Informe um endereço para cada tipo',
  CampoAdicionalPossuiInstrumentoTipoInstrumentoNaoPodeSerRemovido:
    'O Tipo de Instrumento não pode ser removido, pois existe um Instrumento associado ao Campo Adicional',
  CampoAdicionalPossuiModeloInstrumentoTipoInstrumentoNaoPodeSerRemovido:
    'O Tipo de Instrumento não pode ser removido, pois existe um Modelo Instrumento associado ao Campo Adicional',
  pontoCalibracaoNaoPertenceAFaixa: 'Faixa incompatível com a calibração',
  instrumentoPadraoNaoEncontrado:
    'É necessário informar instrumentos para todos os pontos e variáveis',
  instrumentoDaCalibracaoNaoPodeSerSelecionadoEmInstrumentosPadroes:
    'Instrumento da calibração não pode ser selecionado em instrumentos padrões',
  campoAdicionalPossuiInstrumentoTipoInstrumentoNaoPodeSerRemovido:
    'O Tipo de Instrumento não pode ser removido, pois existe um Instrumento associado ao Campo Adicional',
  CampoAdicionalPossuiInstrumentoEdicaoValorNaoPodeSerAlterado:
    'O Valor Edição não pode ser alterado, pois existe um Instrumento associado ao Campo Adicional',
  motivoCancelamentoNomeJaExiste: 'Motivo de cancelamento já existe',
  RoteiroMotivoCancelamentoNaoEncontrado:
    'Motivo de cancelamento não encontrado',
  jaExisteRoteiroNaDataAgendada: 'Já existe um roteiro na data agendada',
  ClienteJaEstaNoRoteiroNaNovaDataAgendamento:
    'Cliente já está no roteiro na nova data de agendamento',
  RoteiroSituacaoDiferenteDePendente:
    'Roteiro com situação diferente de pendente',
  RoteiroNaoEncontrado: 'Roteiro não encontrado',
  ContatoSolicitanteNaoEncontrado: 'Contato solicitante não encontrado',
  dataAgendamentoNaoPodeSerMenorQueDataAtual:
    'Data de agendamento não pode ser menor que a data atual',
  ultimoVeiculoHistoricoNaoEncontrado:
    'Último histórico do veículo não encontrado',
  motivoCancelamentoEmUso: 'Motivo de cancelamento em uso',
  deveSerInformadoPeloMenosUmMotivoCancelamento:
    'Deve ser informado pelo menos um motivo de cancelamento',
  pontosObrigatorio: 'Deve ser inserido pelo menos um ponto',
  deveTerPeloMenosUmaTabelaRegistroParaCadaFaixa:
    'Deve ter pelo menos uma tabela de registro para cada faixa',
  areaAcreditacaoPossuiTiposInstrumentos:
    'Área Acreditação possui Tipos Instrumentos associados',
  contratoDeveEstarNoStatusAguardandoAprovacao:
    'Contrato deve estar como Aguardando Aprovação',
  orcamentoDeveEstarNoStatusAguardandoAprovacao:
    'Orçamento deve estar como Aguardando Aprovação',
  cargoFuncaoPossuiColaboradores:
    'Cargo/Função possui Colaboradores associados',
  categoriaItemPossuiItens: 'Categoria Item possui Itens associados',
  classificacaoInstrumentoPossuiTiposInstrumentos:
    'Classificação Instrumento possui Tipos Instrumentos associados',
  classificacaoFiscalPossuiItens:
    'Classificação Fiscal possui Itens associados',
  formulaEmUsoNoCriterioAceitacao: 'Fórmula em uso no Critério de Aceitação',
  grandezaFisicaEmUsoPorUnidadeMedida:
    'Grandeza Física em uso por Unidade de Medida',
  instrumentoEmUsoPorCriterioAceitacao:
    'Instrumento em uso por Critério de Aceitação',
  instrumentoEmUsoPorSolicitacaoCliente:
    'Instrumento em uso por Solicitação de Cliente',
  instrumentoEmUsoPorRegistroInformativo:
    'Instrumento em uso por Registro Informativo',
  itemEmUsoPorChecklists: 'Item em uso por Checklists',
  contratoJaPossuiRevisao: 'Contrato já possui Revisão',
  OrcamentoJaPossuiRevisao: 'Orçamento já possui Revisão',
  localEstocagemPossuiSetor: 'Local de Estocagem possui Setores associados',
  localExecucaoPossuiServicos: 'Local de Execução possui Serviços associados',
  marcaPossuiModeloInstrumento:
    'Marca possui Modelos de Instrumentos associados',
  marcaPossuiInstrumento: 'Marca possui Instrumentos associados',
  modeloInstrumentoPossuiInstrumento:
    'Modelo de Instrumento possui Instrumentos associados',
  modeloInstrumentoPossuiItem: 'Modelo de Instrumento possui Itens associados',
  modeloInstrumentoPossuiServico:
    'Modelo de Instrumento possui Serviços associados',
  ocorrenciaPadraoPossuiRegistroInformativo:
    'Ocorrência Padrão possui Registros Informativos associados',
  ocorrenciaPadraoPossuiRegistroManutencao:
    'Ocorrência Padrão possui Registros de Manutenção associados',
  ordemServicoPossuiRegistroInformativo:
    'Ordem de Serviço possui Registros Informativos associados',
  participantePossuiCriterioAceitacaoAssociado:
    'Participante possui Critérios de Aceitação associados',
  participantePossuiInstrumentoAssociado:
    'Participante possui Instrumentos associados',
  participantePossuiServicoAssociado: 'Participante possui Serviços associados',
  participantePossuiRegistroManutencaoAssociado:
    'Participante possui Registros de Manutenção associados',
  procedimentoAssociadoEmTipoInstrumento:
    'Procedimento Associado em Tipo de Instrumento',
  servicoAssociadoChecklist: 'Serviço Associado a Checklist',
  setorPossuiColaborador: 'Setor possui Colaboradores associados',
  setorPossuiCriterioAceitacao:
    'Setor possui Critérios de Aceitação associados',
  setorPossuiInstrumento: 'Setor possui Instrumentos associados',
  setorPossuiRegistroInformativo:
    'Setor possui Registros Informativos associados',
  setorPossuiOcorrenciaPadrao: 'Setor possui Ocorrências Padrões Associadas',
  setorPossuiTipoInstrumento: 'Setor possui Tipos de Instrumentos associados',
  tabelaPossuiCriterioAceitacao:
    'Tabela possui Critérios de Aceitação associados',
  tipoCalibracaoPossuiServico: 'Tipo de Calibração possui Serviços associados',
  tipoInstrumentoPossuiChecklistAssociado:
    'Tipo de Instrumento possui Checklists associados',
  tipoInstrumentoPossuiCriterioAceitacaoAssociado:
    'Tipo de Instrumento possui Critérios de Aceitação associados',
  tipoInstrumentoPossuiInstrumentoAssociado:
    'Tipo de Instrumento possui Instrumentos associados',
  tipoInstrumentoPossuiItemAssociado:
    'Tipo de Instrumento possui Itens associados',
  tipoInstrumentoPossuiServicoAssociado:
    'Tipo de Instrumento possui Serviços associados',
  tipoItemPossuiItemAssociado: 'Tipo de Item possui Itens associados',
  tipoSetorPossuiSetorAssociado: 'Tipo de Setor possui Setores associados',
  unidadeMedidaPossuiCriterioAceitacaoAssociado:
    'Unidade de Medida possui Critérios de Aceitação associados',
  unidadeMedidaPossuiItemAssociado: 'Unidade de Medida possui Itens associados',
  unidadeMedidaPossuiServicoAssociado:
    'Unidade de Medida possui Serviços associados',
  instrumentosIdsObrigatorio: 'Deve ser inserido pelo menos um instrumento',
  clienteIdObrigatorio: 'Cliente obrigatório',
  contatoSolicitanteNaoEncontrado: 'Contato solicitante não encontrado',
  roteiroNaoEncontrado: 'Roteiro não encontrado',
  veiculoEmUso: 'Veículo em uso',
  contatoAtendimentoObrigatorio: 'Contato de atendimento obrigatório',
  dataAgendamentoNaoPodeSerMenorOuIgualQueDataAtual:
    'Data de agendamento não pode ser menor ou igual que a data atual',
  observacaoObrigatoria: 'Observação obrigatória',
  instrumentoPossuiOrdemServicoAssociada:
    'Instrumento possui ordem de serviço associada',
  ordemServicoInvalida: 'Ordem de Serviço é inválida',
  instrumentoSemMovimentacao: 'Instrumento sem movimentação',
  TabelaNaoPermitida: 'Tabela de calibração não permitida',
  DeveTerPeloMenosUmaTabelaRegistroParaCadaFaixa:
    'A calibração deve ter pelo menos uma tabela de registro para cada faixa',
  CalibracaoNaoEncontrada: 'Calibração não encontrada',
  InstrumentoNaoPossuiCalibracaoAssinada:
    'Instrumento não possui calibração assinada',
  CalibracaoAtualSemLinhas: 'Calibração atual não possui linhas',
  instrumentoEmUsoNaCalibracao: 'Instrumento em uso na calibração',
  valorReferenciaMenorQue0: 'Valor de referência não pode ser menor que 0',
  valorReferenciaMaiorQue100: 'Valor de referência não pode ser maior que 100',
  escopoPossuiFaixasQueSeCruzam: 'Escopo possui faixas que se cruzam',
  certificadoDeveEstaAssinado: 'O certificado deve estar assinado',
  formulaSistemaNaoPodeSerDuplicada:
    'Fórmula do sistema não pode ser duplicada',
  incertezaAuxiliarIdObrigatorio: 'Incerteza auxiliar obrigatório',
  grausLiberdadeObrigatorio: 'Graus de liberdade obrigatório',
  Maximo19CaracteresCom9CasasDecimaisParaGrausLiberdade:
    'Máximo de 19 caracteres com 9 casas decimais para graus de liberdade',
  ArredondamentoSMCDeveSerVazio: 'Arredondamento SMC deve ser vazio',
  FormulaVariavelIdDeveSerVazio: 'Variável deve ser vazio',
  formulaContribuicaoObrigatorio: 'Fórmula de contribuição obrigatório',
  campoJaExistenteParaAlgumTipoInstrumento:
    'Campo Adicional já existente para algum Tipo de Instrumento',
  tabelaReferenciaObrigatorio: 'Tipo do calculo é obrigatório',
  colunaReferenciaNaoEncontrada: 'Coluna referência não encontrada',
  instrumentoPadraoDoValorReferenciaNaoEncontrado:
    'Instrumento padrão do valor de referência não encontrado',
  ultimaCalibracaoDoInstrumentoPadraoDoValorReferenciaNaoEncontrado:
    'Ultima calibração do instrumento padrão do valor referência não encontrado',
  conformidadeDaUltimaCalibracaoDoInstrumentoPadraoDoValorReferenciaNaoEncontrada:
    'Conformidade da ultima calibração do instrumento padrão do valor de referência não encontrada',
  conversaoPontoCalibracaoNaoEhPossivelParaUnidadeMedidaFormulaMensurando:
    'Conversão ponto calibração não é possivel para unidade de medida da formula do mensurando',
  instrumentoTorqueNaoEncontrado: 'Instrumento torque não encontrado',
  pontoTorqueNaoEncontrado: 'Ponto do torque não encontrado',
  conversaoPontoCalibracaoUltimaCalibracaoDoInstrumentoPadraoNaoEhPossivelParaUnidadeMedidaVariavel:
    'Conversão ponto de calibração ultima calibração do instrumento padão não é possivel para unidade de medida da variavel',
  existeCertificadoFinalizado: 'Existe certificado finalizado',
  dataNascimentoObrigatorio: 'Data de Nascimento é obrigatório',
  dataNascimentoNaoPodeSerMaiorQueAtual:
    'Data de Nascimento não pode ser maior que data atual',
  existemEscoposComMesmaAcreditacaoTipoCalibracaoLocalExecucaoFaixasCruzadas:
    'Existem escopos com a mesma Acreditacao, Tipo de Calibração, Local de Execucao ou Faixas Cruzadas',
  NaoExisteEscopoFaixaQueCobreTabelaAmplitude:
    'Existem faixas dos escopos de serviço que não atendem a amplitude da tabela',
  InstrumentoPadraoPodeSomenteUmaTabelaPorFaixa:
    'Instrumentos padrão podem ter somente uma tabela por faixa na calibração.',
  procedimentoEletronicoTipoCalibracaoSemAcessoCliente:
    'Cliente sem acesso ao tipo calibração do procedimento eletrônico.',
  naoExisteEscopoFaixaQueCobreTabelaAmplitude:
    'Existem faixas dos escopos de serviço que não atendem a amplitude da tabela',
  instrumentoPadraoPodeSomenteUmaTabelaPorFaixa:
    'Instrumentos padrão podem ter somente uma tabela por faixa na calibração.',
  veffDeveSerMenorOuIgualA1000: 'Veff deve ser menor ou igual a 1000',
  arredondamentoSMCInvalido: 'Arredondamento SMC inválido',
  usuarioNaoPodeExecutarCalibracao: 'Usuário não pode executar calibração',
  tabelaCalibracaoNaoConfigurada: 'Tabela de calibração não configurada',
  VeffDeveSerMenorOuIgualA1000: 'Veff deve ser menor ou igual a 1000',
  ArredondamentoSMCInvalido: 'Arredondamento SMC inválido',
  quantidadeOrcadaObrigatorio: 'Quantidade orçada em peças é obrigatório',
  percentualObrigatorio: 'Percentual Obrigatório',
  participanteContaNaoPodeExecutarCalibracaoInterna:
    'Participante não pode executar calibração interna',
  ordemServicoIdNaoPodeSerInformadoComTipoEnvioMaterialDiferenteDeEntrega:
    'Ordem de Serviço não pode ser informado com tipo de envio de material diferente de Entrega',
  kmChegadaDeveSerMaiorQueKmSaida:
    'Km de chegada deve ser maior que Km de saída',
  roteiroSituacaoDiferenteDePendente:
    'Já existe um roteiro programado na data agendada',
  naoExisteRoteiroProgramadoParaHoje: 'Não existe roteiro programado para hoje',
  situacaoAvaliacaoInvalida: 'Situação da avaliação inválida',
  calibracaoForaDoEscopoDeServico: 'Calibração fora do escopo de serviço',
  existemEnviosMateriaisEmExecucaoOuConcluidos:
    'Existem envios de materiais em execução ou concluídos',
  calibracaoNaoPodeVoltarParaEmAndamento:
    'Calibração não pode voltar para Em Andamento',
  emailTemplateNaoEncontrado: 'Email template não encontrado',
  nomeExibicaoObrigatorio: 'Nome de exibição obrigatório',
  assuntoObrigatorio: 'Assunto obrigatório',
  instrumentoDeveEstarNaSituacaoSemMovimento:
    'Instrumento deve estar na situação Sem Movimento',
  existemEnviosMateriaisNaoFinalizados:
    'Existem envios de materiais não finalizados',
  envioMaterialNaoPodeSerAlterado: 'Envio de Material não pode ser alterado',
  envioMaterialSemQrCode:
    'Obrigatório gerar os QR Codes dos Envios de Materiais',
  formulaEmUsoNaIncertezaRevisao: 'Fórmula em uso na Incerteza',
  unidadeMedidaConversaoNaoEncontrada:
    'Unidade de Medida de Conversão não encontrada',
  amplitudeForaDoEscopo: 'Amplitude fora do escopo',
  ordemServicoParcialNaoDeveReceberTodosOsInstrumentos:
    'Ordem de Serviço parcial não deve receber todos os instrumentos',
  jaExisteAnaliseTecnicaEmAndamento:
    'Já existe uma análise técnica em andamento',
  analiseTecnicaNaoPodeSerFinalizadaSemServicoPeca:
    'Análise técnica não pode ser finalizada sem serviço ou peça',
  maximo1000CaracteresParaObservacao:
    'Máximo de 1000 caracteres para a observação',
  trocaDeStatusDeOrdemServicoInstrumentoNaoPermitida:
    'Troca de status de ordem de serviço de instrumento não permitida',
  naoExistemStatusParaSeremAlterados: 'Não existem status para serem alterados',
  naoFoiPossivelReverterStatusOrdemServico:
    'Não foi possível reverter a situação da ordem de serviço',
  envioMaterialNaoPodeSerAssociado:
    'Já existe uma Ordem de Serviço vinculada a este Envio de Material ou o Envio de Materal já foi Concluído',
  clienteNaoPodeEnvioMaterialNaDataAgendada:
    'Não é possível enviar o material na data agendada',
  dataInicialNaoPodeSerAnteriorADataAtual:
    'Data inicial não pode ser anterior a data atual',
  dataInicialMaiorQueDataFinal: 'Data inicial maior que data final',
  instrumentoPossuiEnvioDeMaterialEmAberto:
    'Instrumento possui envio de material em aberto',
  semPermissao: 'Sem permissão',
  situacaoAgendamentoNaoPermiteAlteracao:
    'Situação do agendamento não permite alteração',
  instrumentoPadraoNaoPodeSerSelecionadoPorContratante:
    'Instrumento padrão não pode ser selecionado por contratante',
  instrumentoJaEstaEmUmaOrdemServico:
    'Instrumento já está em uma ordem de serviço',
  situacaoNaoPermiteProgramarAgendamento:
    'Situação não permite programar agendamento',
  instrumentosInseridosDuranteAgendamentoNaoPodemSerExcluidos:
    'Instrumentos inseridos durante agendamento não podem ser excluídos',
  CheckInJaRealizado: 'Check-in já realizado',
  agendamentoNaoEncontrado: 'Agendamento não encontrado',
  analiseCriticaNaoPodeSerRealizadaNesseStatus:
    'Análise crítica não pode ser realizada nesse status',
  restricao_por_ip: 'Erro de acesso. Restrição por IP',
  restricao_por_horario_trabalho:
    'Erro de acesso. Colaborador está fora do horário de trabalho.',
  restricao_por_ausencia_programada:
    'Erro de acesso.Você possui ausência programada neste período',
  localdeexecucaoservicodiferentedoorcamento:
    'Local de Execucao do Serviço é Diferente do Orçamento',
  ordemServicoPossuiEnvioMaterial: 'Ordem de Serviço possui Envio de Material',
  ordemServicoPossuiAnaliseTecnica: 'Ordem de Serviço possui Análise Técnica',
  descricaoJustificativaSemConsertoObrigatorio:
    'Descrição da Justificativa sem conserto é obrigatória',
  formatoEnderecoIpInicialInvalido: 'Formato de endereço IP inicial inválido',
  formatoEnderecoIpFinalInvalido: 'Formato de endereço IP final inválido',
  formatoEnderecoIpInvalido: 'Formato de endereço IP inválido',
  formatoDoModeloDeEmailInvalido: 'Formato do modelo de email inválido',
  tipoEnvioDeveSerNuloParaAgendamento:
    'Tipo de envio deve ser nulo para agendamento',
  calibracaoOuManutencoesOuPecasDeveSerInformado:
    'Obrigatório ter algum serviço ou peça.',
  quantidadeVolumesMaiorQueZero:
    'Quantidade de Volumes deve ser maior que zero',
  prazoEntregaEmDiasDeveSerInformado:
    'Prazo de Entrega em dias deve ser informado',
  localExecucaoDeveSerInformado: 'Local de Execução deve ser informado',
  condicaoPagamentoIdObrigatorio: 'Condição de pagamento obrigatória',
  localDeExecucaoServicoDiferenteDoOrcamento:
    'Local de Execução do Serviço é Diferente do Orçamento',
  statusDoOrcamentoNaoPermiteDeletar: 'Status do Orçamento não permite deletar',
  ordemServicoInstrumentoNaoEncontrado:
    'Ordem de Serviço do Instrumento não encontrado',
  kanbanConfiguracaoNaoEncontrado: 'Configuração do Kanban não encontrada',
  dataPrevisaoEntregaDeveSerMaiorQueDataRecebimento:
    'Data de previsão de entrega deve ser maior que a data de recebimento',
  instrumentoNaoPodeSerRemovido: 'Instrumento não pode ser removido',
  emailJaCadastrado: 'E-mail já cadastrado',
  ordemServicoInvalidaEntrega:
    "Ordem de serviço inválida para Entrega (Envio Material). Deve ter a situação auxiliar como 'Aguardando Entrega' e situação como Calibrado, ou Não Calibrado, ou Reprovado",
  procedimentoEletronicoNaoPublicado: 'Procedimento eletrônico não publicado',
  tipoVisitaJaCadastrado: 'Tipo de visita já cadastrado',
  tipoVisitaPossuiAgendamento: 'Tipo de visita possui agendamento',
  existeServicoCalibracaoDefinidoParaEsseInstrumento:
    'Existe serviço de calibração definido para esse instrumento',
  situacaoNaoPermiteFuncao: 'Situação não permite função',
  numeroParcelasInvalido: 'Número de parcelas inválido',
  informePeloMenosUmCertificado: 'Informe pelo menos um certificado',
  acessoNegadoAusenciaColaborador: 'Acesso negado. Colaborador em ausência',
  acessoNegadoHorarioTrabalho:
    'Acesso negado. Colaborador fora do horário de trabalho',
  acessoNegadoForaDoHorarioDeTrabalho:
    'Acesso negado. Fora do horário de trabalho',
  acessoNegadoPorRestricaoDeIp: 'Acesso negado. Endereço IP restringido',
  colaboradorPossuiUsuario: 'Colaborador possui usuário',
  maximo5000CaracteresParaDescricao:
    'Maximo de 5000 caracteres para a descrição',
  dataResolucaoObrigatoria:
    'Informe a Data da Resolução clicando em "Editar" antes de encerrar.',
  usuarioResolucaoObrigatorio:
    'Informe o Usuário Responsável pela Resolução clicando em "Editar" antes de encerrar.',
  descricaoResolucaoObrigatoria:
    'Informe a Descrição da Resolução clicando em "Editar" antes de encerrar.',
  localExecucaoPossuiSequenciaDeSetores:
    'Não é possível excluir um local de execução que está associado a um ou mais instrumentos.',
  rangeDeIpInvalido: 'Um ou mais intervalos de IPs são inválidos.',
  formulaSistemaNaoPodeSerRemovida: 'Fórmula do sistema não pode ser removida',
  servicoAssociadoOrdemServico: 'Serviço associado a Ordem de Serviço',
  servicoAssociadoOrcamento: 'Serviço associado a Orçamento',
  servicoAssociadoContrato: 'Serviço associado a Contrato',
  aoMenosUmInstrumentoDeveSerInformado:
    'Ao menos um instrumento deve ser informado.',
  contratoNaoPodeSerEditado: 'Contrato não pode ser editado',
  dataComFormatoInvalido: 'O formato da data é inválido',
  revisaoNaoPodeSerPublicadaSemJustificativa:
    'Revisão não pode ser publicada sem justificativa',
  simboloVariavelDeveConterApenasLetrasENumeros:
    'O simbolo da variável deve conter apenas letras e números',
  simboloFormulaDeveConterApenasLetrasENumeros:
    'O simbolo da fórmula deve conter apenas letras e números',
  verificacaoIntermediariaNaoEncontrada:
    'Verificação intermediária não encontrada',
  maximoDe15CaracteresCom5CasasDecimaisParaPrimeiroCiclo:
    'Máximo de 15 caracteres com 5 cadas decimais para primeiro ciclo',
  verificacaoIntermediariaDeveEstarEmAndamento:
    'Verificação Intermediária deve estar em andamento',
  naoPodeAlterarSimboloVariavelOuFormulaVariavelEmUsoPorTabela:
    'Símbolo ou Fórmula não pode ser alterado porque está em uso em Tabela de Registro',
  variavelEmUsoPorOutrasFormulas: 'Variável em uso por outras fórmulas',
  pontosDuplicadosExistente:
    'Pontos Duplicados Existentes',
  ValorFatorConversaoDeveSerMaiorQueZero:
    'Valor Fator de Conversão Deve Ser Maior que Zero',
    erroMontarKanban: 'Erro ao montar o kanban',
    quantidadeCartoesLinhaInvalido: 'A quantidade de cartões por linha deve ser entre 1 e 4',
    prioridadeObrigatoriaParaCampoPrioridade: 'Prioridade obrigatória para campo valor',
    instrumentosNaoEncontrado: 'Instrumentos não encontrados',
};
